import { FC, useState, useEffect } from "react";
import { usePrivy, useLogin } from "@privy-io/react-auth";
import Navigation from "@/components/Navigation";
import WelcomeScreen from "@/components/WelcomeScreen";
import Button from "@/components/ui/Button";
import ButtonGrid from "@/components/ButtonGrid";
import Text from "@/components/ui/Text";
import TextFlex from "@/components/ui/TextFlex";
import { useNavigate } from "react-router-dom";
import WhiteContainer from "@/components/ui/WhiteContainer";
import AlertContainer from "@/components/AlertContainer";
import { useDappmons } from "@/hooks/useDappmons";

export const Home: FC = () => {
  const navigate = useNavigate();
  const { ready, authenticated } = usePrivy();
  const { login } = useLogin({
    onComplete: (user, isNewUser, wasAlreadyAuthenticated) => {
      if (!wasAlreadyAuthenticated) {
        navigate("/start");
      }
    },
  });
  const isConnected = ready && authenticated;
  const { dappmons } = useDappmons();
  const [alertMessage, setAlertMessage] = useState<string>(
    "Welcome to Dappmon."
  );

  useEffect(() => {
    if (isConnected) {
      if ((dappmons?.length || 0) > 0) {
        navigate("/dappmons");
      }
    }
  }, [isConnected, navigate, dappmons]);

  const handleNext = () => {
    navigate("/start");
  };

  const handleMouseOver = (message: string) => {
    setAlertMessage(message);
  };

  const handleMouseOut = () => {
    setAlertMessage("Welcome to Dappmon.");
  };

  return (
    <>
      <div className="app">
        <div className="app-container">
          <Navigation />
          <WhiteContainer>
            <TextFlex>
              <Text className="lg centered">Dappmon</Text>
            </TextFlex>
          </WhiteContainer>
          <WelcomeScreen />
          <AlertContainer message={alertMessage} />
          <WhiteContainer>
            <ButtonGrid className="connect">
              <Button
                onClick={isConnected ? handleNext : login}
                className="connect w-button animate-pulse transition-all"
                onMouseOver={() =>
                  isConnected
                    ? handleMouseOver("Continue?")
                    : handleMouseOver("Connect?")
                }
                onMouseOut={handleMouseOut}
              >
                {isConnected ? "Continue" : "Connect"}
              </Button>
            </ButtonGrid>
          </WhiteContainer>
        </div>
      </div>
    </>
  );
};

export default Home;
