import { FC, useState } from "react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import TransferDappmon from "./TransferDappmon";
import BurnDappmon from "./BurnDappmon";
import Button from "./ui/Button";
import ButtonGrid from "./ButtonGrid";
import { useMUD } from "@/MUDContext";

interface ProfileActionButtonsProps {
  dappmonId: string;
  dappmonName: string;
  setAlertMessage: (message: string) => void;
  isPublicView: boolean;
}

const ProfileActionButtons: FC<ProfileActionButtonsProps> = ({
  dappmonId,
  dappmonName,
  setAlertMessage,
  isPublicView,
}) => {
  const mud = useMUD();
  const [isTransferring, setIsTransferring] = useState(false);
  const [isBurning, setIsBurning] = useState(false);
  const navigate = useNavigate();

  const handleEvolve = async () => {
    try {
      const evolvable = await mud?.systemCalls?.canEvolve(BigInt(dappmonId));
      if (evolvable) {
        await mud?.systemCalls?.evolve(BigInt(dappmonId));
        toast.success(`${dappmonName} has evolved!`);
      } else {
        toast.error(`${dappmonName} cannot evolve yet.`);
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Failed to evolve ${dappmonName}: ${error.message}`);
      } else {
        toast.error(`Failed to evolve ${dappmonName}: Unknown error`);
      }
    }
  };

  const handleViewNFT = () => {
    const url = `https://magiceden.io/item-details/base/0x066d1050784e0d5d0f12dba1b5b2ab41c51c742f/${dappmonId}`;
    window.open(url, "_blank");
  };

  const handleMouseOver = (message: string) => {
    setAlertMessage(message);
  };

  const handleMouseOut = () => {
    setAlertMessage("Select an action");
  };

  if (isPublicView) {
    return (
      <ButtonGrid>
        <Button
          className="view"
          onClick={handleViewNFT}
          onMouseOver={() => handleMouseOver("View this Dappmon NFT")}
          onMouseOut={handleMouseOut}
        >
          View
        </Button>
        <Button
          className="dark cancel"
          onClick={() => navigate(-1)}
          onMouseOver={() => handleMouseOver("Close and go back")}
          onMouseOut={handleMouseOut}
        >
          Close
        </Button>
      </ButtonGrid>
    );
  }

  return (
    <>
      {!isTransferring && !isBurning && (
        <ButtonGrid>
          <Button
            className="evolve"
            onClick={handleEvolve}
            onMouseOver={() => handleMouseOver("Evolve your Dappmon")}
            onMouseOut={handleMouseOut}
          >
            Evolve
          </Button>
          <Button
            className="view"
            onClick={handleViewNFT}
            onMouseOver={() => handleMouseOver("View your Dappmon NFT")}
            onMouseOut={handleMouseOut}
          >
            View
          </Button>
          <Button
            className="transfer"
            onClick={() => setIsTransferring(true)}
            onMouseOver={() => handleMouseOver("Transfer your Dappmon")}
            onMouseOut={handleMouseOut}
          >
            Transfer
          </Button>
          <Button
            className="dark cancel"
            onClick={() => navigate(-1)}
            onMouseOver={() => handleMouseOver("Close and go back")}
            onMouseOut={handleMouseOut}
          >
            Close
          </Button>
        </ButtonGrid>
      )}
      {isTransferring && (
        <TransferDappmon
          dappmonId={dappmonId}
          onCancel={() => setIsTransferring(false)}
        />
      )}
      {isBurning && (
        <BurnDappmon
          dappmonId={dappmonId}
          onCancel={() => setIsBurning(false)}
        />
      )}
    </>
  );
};

export default ProfileActionButtons;
