import React, { FC, ReactNode } from 'react';

interface GameScreenProps {
  children: ReactNode;
  title: string;
}

const GameScreen: FC<GameScreenProps> = ({ children }) => {
  return (
   <>
      <div className="game-screen-container">
        <div className="game-screen-wrapper">
          {children}
        </div>
      </div>
   </>
  );
};

export default GameScreen;
