import { setupNetwork } from "./setupNetwork";
import { createSystemCalls } from "./createSystemCalls";
import { PublicClient, WalletClient } from "viem";
import { MUDStateResult } from "./setupState";

export type SetupResult = Awaited<ReturnType<typeof setup>>;

export async function setup(
  mudState: MUDStateResult,
  publicClient: PublicClient,
  walletClient: WalletClient
) {
  const network = await setupNetwork(publicClient, walletClient);
  const systemCalls = createSystemCalls({ ...mudState, ...network });

  return {
    network,
    systemCalls,
  };
}

export type SetupNetworkResult = Awaited<ReturnType<typeof setupNetwork>>;
