import { Account } from "viem";
import { SetupNetworkResult } from "./setupNetwork";
import { chain } from "@/mud/supportedChains";
import { MUDStateResult } from "./setupState";

export type SystemCalls = ReturnType<typeof createSystemCalls>;

export function createSystemCalls({
  worldContract,
  packagesContract,
  dappmonsContract,
  skillsContract,
  itemsContract,
  walletClient,
  waitForTransaction,
}: SetupNetworkResult & MUDStateResult) {
  const account = walletClient.account as Account;

  const getPackagePrice = async () => {
    return worldContract.read.getPackagePrice();
  };

  const purchasePackage = async () => {
    const price = await worldContract.read.getPackagePrice();

    await worldContract.simulate.purchasePackage({
      value: price,
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.purchasePackage({
      value: price,
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const openPackage = async (packageId: bigint) => {
    await worldContract.simulate.openPackage([packageId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.openPackage([packageId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const getPackageBalance = async () => {
    if (!walletClient.account?.address) {
      return 0n;
    }
    return packagesContract.read.balanceOf([walletClient.account.address]);
  };

  const getPackageIds = async () => {
    if (!walletClient.account?.address) {
      return [];
    }
    const total = await getPackageBalance();
    const tokenIds: bigint[] = [];

    for (let i = 0; i < total; i += 1) {
      const tokenId = await packagesContract.read.tokenOfOwnerByIndex([
        walletClient.account.address,
        BigInt(i),
      ]);
      tokenIds.push(tokenId);
    }

    return tokenIds;
  };

  const getDappmonOwnerOf = async (dappmonId: bigint) => {
    return dappmonsContract.read.ownerOf([dappmonId]);
  };

  const getDappmonBalance = async () => {
    if (!walletClient.account?.address) {
      return 0n;
    }
    return dappmonsContract.read.balanceOf([walletClient.account.address]);
  };

  const getAllDappmonIds = async () => {
    if (!walletClient.account?.address) {
      return [];
    }

    const supply = await dappmonsContract.read.totalSupply();
    const tokenIds: bigint[] = [];

    for (let i = 0; i < supply; i += 1) {
      const tokenId = await dappmonsContract.read.tokenByIndex([BigInt(i)]);
      tokenIds.push(tokenId);
    }

    return tokenIds;
  };

  const getDappmonIds = async () => {
    if (!walletClient.account?.address) {
      return [];
    }
    const total = await getDappmonBalance();
    const tokenIds: bigint[] = [];

    for (let i = 0; i < total; i += 1) {
      const tokenId = await dappmonsContract.read.tokenOfOwnerByIndex([
        walletClient.account.address,
        BigInt(i),
      ]);
      tokenIds.push(tokenId);
    }

    return tokenIds;
  };

  const hatch = async (dappmonId: bigint) => {
    await worldContract.simulate.hatch([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.hatch([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const bandage = async (dappmonId: bigint) => {
    await worldContract.simulate.bandage([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.bandage([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const cure = async (dappmonId: bigint) => {
    await worldContract.simulate.cure([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.cure([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const feed = async (dappmonId: bigint) => {
    await worldContract.simulate.feed([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.feed([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const clean = async (dappmonId: bigint) => {
    await worldContract.simulate.clean([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.clean([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const protein = async (dappmonId: bigint) => {
    await worldContract.simulate.protein([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.protein([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const train = async (dappmonId: bigint) => {
    await worldContract.simulate.train([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.train([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const evolve = async (dappmonId: bigint) => {
    await worldContract.simulate.evolve([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.evolve([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const transfer = async (dappmonId: bigint, address: `0x${string}`) => {
    if (!walletClient.account?.address) {
      throw new Error("Wallet Not Connected");
    }
    const tx = await dappmonsContract.write.transferFrom(
      [walletClient.account.address, address, dappmonId],
      {
        chain,
        account: account.address,
      }
    );
    await waitForTransaction(tx);
  };

  const burn = async (dappmonId: bigint) => {
    await dappmonsContract.simulate.burn([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await dappmonsContract.write.burn([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const sleep = async (dappmonId: bigint) => {
    await worldContract.simulate.sleep([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.sleep([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const canEvolve = (dappmonId: bigint) => {
    return worldContract.read.canEvolve([dappmonId]);
  };

  const getEffort = (dappmonId: bigint) => {
    return worldContract.read.getEffort([dappmonId]);
  };

  const getHunger = (dappmonId: bigint) => {
    return worldContract.read.getHunger([dappmonId]);
  };

  const getPoopCount = (dappmonId: bigint) => {
    return worldContract.read.getPoopCount([dappmonId]);
  };

  const hasDied = (dappmonId: bigint) => {
    return worldContract.read.hasDied([dappmonId]);
  };

  const isSleeping = (dappmonId: bigint) => {
    return worldContract.read.isSleeping([dappmonId]);
  };

  const isTired = (dappmonId: bigint) => {
    return worldContract.read.isTired([dappmonId]);
  };

  const isSick = (dappmonId: bigint) => {
    return worldContract.read.isSick([dappmonId]);
  };

  const isInjured = (dappmonId: bigint) => {
    return worldContract.read.isInjured([dappmonId]);
  };

  const getWeight = (dappmonId: bigint) => {
    return worldContract.read.getWeight([dappmonId]);
  };

  const timeUntilEvolve = (dappmonId: bigint) => {
    return worldContract.read.timeUntilEvolve([dappmonId]);
  };

  const getDappmon = (dappmonId: bigint) => {
    return worldContract.read.getDappmon([dappmonId]);
  };

  const claimGiftEgg = async (dappmonId: bigint) => {
    await worldContract.simulate.claimGiftEgg([dappmonId], {
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.claimGiftEgg([dappmonId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const getGiftEggsAvailable = (dappmonId: bigint) => {
    return worldContract.read.getGiftEggsAvailable([dappmonId]);
  };

  const getItemsBalance = (itemId: bigint) => {
    if (!walletClient.account?.address) {
      return 0n;
    }
    return itemsContract.read.balanceOf([walletClient.account.address, itemId]);
  };

  const getSpeed = (dappmonId: bigint) => {
    return dappmonsContract.read.getSpeed([dappmonId]);
  };

  const getAttack = (dappmonId: bigint) => {
    return dappmonsContract.read.getAttack([dappmonId]);
  };

  const getDefense = (dappmonId: bigint) => {
    return dappmonsContract.read.getDef([dappmonId]);
  };

  const getTech = (dappmonId: bigint) => {
    return dappmonsContract.read.getTech([dappmonId]);
  };

  const getTechDefense = (dappmonId: bigint) => {
    return dappmonsContract.read.getTechDef([dappmonId]);
  };

  const getHp = (dappmonId: bigint) => {
    return dappmonsContract.read.getHp([dappmonId]);
  };

  const getType = (dappmonId: bigint) => {
    return dappmonsContract.read.getType([dappmonId]);
  };

  const getBasePower = (dappmonId: bigint) => {
    return dappmonsContract.read.getBasePower([dappmonId]);
  };

  /* Shop */
  const getPackagePriceFromShop = async () => {
    return worldContract.read.getPackagePrice();
  };

  const purchasePackageFromShop = async () => {
    const price = await getPackagePriceFromShop();
    await worldContract.simulate.purchasePackage({
      value: price,
      chain,
      account: account.address,
    });

    const tx = await worldContract.write.purchasePackage({
      value: price,
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const getShopSlotPrice = async (shopSlot: number) => {
    return worldContract.read.getShopSlotPrice([shopSlot]);
  };

  const getShopSlotItemId = async (shopSlot: number) => {
    return worldContract.read.getShopSlotItemId([shopSlot]);
  };

  const purchaseItemFromShop = async (shopSlot: number) => {
    const price = await getShopSlotPrice(shopSlot);
    await worldContract.simulate.purchaseItem([shopSlot], {
      value: price,
      chain,
      account: account.address,
    });
    const tx = await worldContract.write.purchaseItem([shopSlot], {
      value: price,
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const getAllSkillIds = async () => {
    const skillCount = 54; // Assuming you have 54 skills
    const skillIds = [];
    for (let i = 1; i <= skillCount; i++) {
      skillIds.push(BigInt(i));
    }
    return skillIds;
  };

  const getSkillType = async (skillId: bigint) => {
    return skillsContract.read.getType([skillId]);
  };

  const getSkillCategory = async (skillId: bigint) => {
    return skillsContract.read.getCategory([skillId]);
  };

  const getSkillUse = async (skillId: bigint) => {
    return skillsContract.read.getUse([skillId]);
  };

  const getSkillDamage = async (skillId: bigint) => {
    return skillsContract.read.getDamage([skillId]);
  };

  const getSkillHeal = async (skillId: bigint) => {
    return skillsContract.read.getHeal([skillId]);
  };

  const getSkillAttribute = async (skillId: bigint) => {
    return skillsContract.read.getAttribute([skillId]);
  };

  const getSkillIncrease = async (skillId: bigint) => {
    return skillsContract.read.getIncrease([skillId]);
  };

  const getSkillDecrease = async (skillId: bigint) => {
    return skillsContract.read.getDecrease([skillId]);
  };

  const getSkillBalance = async (skillId: bigint, address: `0x${string}`) => {
    return skillsContract.read.balanceOf([address, skillId]);
  };

  const learnSkill = async (
    dappmonId: bigint,
    skillSlot: number,
    skillId: bigint
  ) => {
    const tx = await worldContract.write.learn(
      [dappmonId, skillSlot, skillId],
      {
        chain,
        account: account.address,
      }
    );
    await waitForTransaction(tx);
  };

  const challenge = async (dappmonId: bigint, targetId: bigint) => {
    const tx = await worldContract.write.challenge([dappmonId, targetId], {
      chain,
      account: account.address,
    });
    await waitForTransaction(tx);
  };

  const accept = async (
    dappmonId: bigint,
    challengerId: bigint,
    challengerIndex: bigint
  ) => {
    const tx = await worldContract.write.accept(
      [dappmonId, challengerId, challengerIndex],
      {
        chain,
        account: account.address,
      }
    );
    await waitForTransaction(tx);
  };

  const reject = async (
    dappmonId: bigint,
    challengerId: bigint,
    challengerIndex: bigint
  ) => {
    const tx = await worldContract.write.reject(
      [dappmonId, challengerId, challengerIndex],
      {
        chain,
        account: account.address,
      }
    );
    await waitForTransaction(tx);
  };

  return {
    getPackagePrice,
    purchasePackage,
    openPackage,
    getPackageBalance,
    getPackageIds,
    getDappmonOwnerOf,
    getDappmonBalance,
    getAllDappmonIds,
    getDappmonIds,
    hatch,
    bandage,
    cure,
    feed,
    clean,
    protein,
    train,
    evolve,
    transfer,
    burn,
    sleep,
    claimGiftEgg,
    canEvolve,
    getEffort,
    getHunger,
    getPoopCount,
    hasDied,
    isSleeping,
    isTired,
    isSick,
    isInjured,
    getWeight,
    timeUntilEvolve,
    getDappmon,
    getGiftEggsAvailable,
    getItemsBalance,
    getSpeed,
    getAttack,
    getDefense,
    getTech,
    getTechDefense,
    getHp,
    getType,
    getBasePower,
    getPackagePriceFromShop,
    purchasePackageFromShop,
    getShopSlotPrice,
    getShopSlotItemId,
    purchaseItemFromShop,
    getAllSkillIds,
    getSkillType,
    getSkillCategory,
    getSkillUse,
    getSkillDamage,
    getSkillHeal,
    getSkillAttribute,
    getSkillIncrease,
    getSkillDecrease,
    getSkillBalance,
    learnSkill,
    challenge,
    accept,
    reject,
  };
}
