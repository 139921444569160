/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Text from "./ui/Text";
import WhiteContainer from "./ui/WhiteContainer";

const TermsContent: React.FC = () => {
  return (
    <WhiteContainer>
        <div className="left-align">
      <Text className="xl bold">Terms and Conditions</Text>
      <Text className="md">Last Updated: [July 7, 2024]</Text>
      <Text className="lg bold">1. Introduction</Text>
      <Text className="md">
        Welcome to Dappmon. These terms and conditions outline the rules and regulations for the use of Dappmon's website and application, owned and operated by Strawberry Holding Company, LLC, located in California, USA. By accessing or using our service, you agree to comply with and be bound by these terms.
      </Text>

      <Text className="lg bold">2. Intellectual Property Rights</Text>
      <Text className="md">
        All intellectual property rights for Dappmon, including graphics, logos, trademarks, and software, are the property of Strawberry Holding Company, LLC. Users are granted a limited license to use the application for personal, non-commercial use only.
      </Text>

      <Text className="lg bold">3. User Responsibilities</Text>
      <Text className="md">
        Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. Users must not use the service for any unlawful or prohibited activities.
      </Text>

      <Text className="lg bold">4. NFTs and Blockchain</Text>
      <Text className="md">
        Dappmon utilizes NFTs and blockchain technology. While we provide the platform, we do not have control over the blockchain and are not responsible for any loss of assets. Users are solely responsible for managing their blockchain assets.
      </Text>

      <Text className="lg bold">5. No Financial Advice</Text>
      <Text className="md">
        Dappmon is an experimental virtual pet game and is explicitly not a financial tool. Any data or content provided by Dappmon should not be considered as financial advice.
      </Text>

      <Text className="lg bold">6. Limitation of Liability</Text>
      <Text className="md">
        Strawberry Holding Company, LLC shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the service, even if we have been advised of the possibility of such damages.
      </Text>

      <Text className="lg bold">7. Indemnification</Text>
      <Text className="md">
        You agree to indemnify and hold Strawberry Holding Company, LLC harmless from any claims, losses, damages, liabilities, including legal fees, arising out of your use or misuse of our services, your violation of these terms, or your violation of any rights of a third party.
      </Text>

      <Text className="lg bold">8. Governing Law</Text>
      <Text className="md">
        These terms are governed by and construed in accordance with the laws of California, USA, and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
      </Text>

      <Text className="lg bold">9. Changes to Terms</Text>
      <Text className="md">
        We reserve the right to update these terms at any time. Users will be notified of any changes through our application or website. Continued use of the service constitutes acceptance of the updated terms.
      </Text>
      </div>
    </WhiteContainer>
  );
};

export default TermsContent;
