import { FC, useCallback, useEffect, useState } from "react";
import Button from "@/components/ui/Button";
import ButtonGrid from "@/components/ButtonGrid";
import Navigation from "@/components/Navigation";
import GameScreen from "@/components/GameScreen";
import WhiteContainer from "@/components/ui/WhiteContainer";
import TextFlex from "@/components/ui/TextFlex";
import { useMUD } from "@/MUDContext";
import { useNavigate, useParams } from "react-router-dom";
import { useDappmon } from "@/hooks/useDappmon";
import { toast } from "sonner";
import { handleError } from "@/lib/error";
import DappmonAnimation from "@/components/DappmonAnimation";
import HatchingAnimation from "@/components/HatchingAnimation";
import Text from "@/components/ui/Text";
import AlertContainer from "@/components/AlertContainer";
import { useTimer } from "react-timer-hook";
import { StageType } from "@/types";
import { useAuthenticated } from "@/hooks/useAuthenticated";

const zeroPad = (num: number): string => {
  return num.toString().padStart(2, "0");
};

export const Hatch: FC = () => {
  useAuthenticated();
  const mud = useMUD();
  const { dappmonId } = useParams<{ dappmonId: string }>();
  const [loading, setLoading] = useState(false);
  const [isEvolving, setIsEvolving] = useState(false);
  const navigate = useNavigate();
  const { dappmon, refetch } = useDappmon(
    dappmonId ? BigInt(dappmonId) : undefined
  );
  const [alertMessage, setAlertMessage] = useState<string>(
    "Hatch your Dappmon."
  );

  const hatchedAt = dappmon?.hatchedAt?.timestamp || 0;
  const isHatching = hatchedAt > 0 && dappmon?.stage?.value === StageType.Egg;

  const expiryTimestamp = new Date(
    (Number(dappmon?.evolvedAt?.value || 0) +
      Number(dappmon?.timeUntilEvolve || 0)) *
      1000
  );

  const { seconds, minutes, hours, isRunning, restart } = useTimer({
    expiryTimestamp:
      expiryTimestamp > new Date(1970, 0, 1) ? expiryTimestamp : new Date(),
    autoStart: expiryTimestamp > new Date(1970, 0, 1),
  });

  useEffect(() => {
    if (!isRunning && hatchedAt > 0) {
      const newExpiryTimestamp = new Date(
        (Number(dappmon?.evolvedAt?.value || 0) +
          Number(dappmon?.timeUntilEvolve || 0)) *
          1000
      );
      if (newExpiryTimestamp > new Date(1970, 0, 1)) {
        restart(newExpiryTimestamp);
      }
    }
  }, [dappmon, hatchedAt, isRunning, restart]);

  useEffect(() => {
    if (isEvolving) {
      setTimeout(() => {
        setIsEvolving(false);
        navigate(`/dappmons/${dappmon?.dappmonId}`);
      }, 3000);
    }
  }, [isEvolving, navigate, dappmon]);

  const readyToEvolve = hatchedAt > 0 && expiryTimestamp <= new Date();

  const handleHatch = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !dappmon || !dappmon.dappmonId) return;

      setLoading(true);
      await mud.systemCalls.hatch(dappmon.dappmonId);
      toastId = toast.success("Hatching started!");
      refetch();
    } catch (err) {
      handleError(err, toastId);
    } finally {
      setLoading(false);
    }
  }, [mud, dappmon, refetch]);

  const handleEvolve = useCallback(async () => {
    try {
      if (!mud?.systemCalls || !dappmon || !dappmon.dappmonId) return;

      setLoading(true);
      await mud.systemCalls.evolve(dappmon.dappmonId);

      setIsEvolving(true);
      refetch();
    } catch (err) {
      handleError(err);
      setLoading(false);
    }
  }, [mud, dappmon, refetch]);

  return (
    <>
      <div className="app">
      <div className="app-container">
        <Navigation />
        <WhiteContainer>
          <TextFlex>
            <Text className="lg centered">Time to hatch!</Text>
          </TextFlex>
        </WhiteContainer>
        <GameScreen title="Hatch">
          <div className="content-wrapper">
            {isEvolving ? (
              <HatchingAnimation />
            ) : isHatching ? (
              <>
                <DappmonAnimation
                  id={Number(dappmon?.dappmon || 0)}
                  state="idle"
                />
                <WhiteContainer className="mt-0 mb-0 mr-auto ml-auto max-w-md">
                  <Text className="xxl bold">
                    {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                    <br />
                  </Text>
                </WhiteContainer>
              </>
            ) : (
              <>
                <DappmonAnimation id={Number(dappmon?.dappmon || 1)} state="idle" />
                <WhiteContainer className="mx-auto  sm:w-1/2">
                <Text className="xxl centered">
                  Birthdate:<br/> <b>{new Date().toLocaleString()}</b>
                  <br />
                </Text>
                </WhiteContainer>
              </>
            )}
          </div>
        </GameScreen>
        <AlertContainer message={alertMessage} />
        <WhiteContainer>
          <ButtonGrid className="hatch">
            {readyToEvolve ? (
              <Button
                disabled={loading}
                className="hatch w-button"
                onClick={handleEvolve}
                onMouseOver={() =>
                  setAlertMessage("Ready to reveal your Dappmon!")
                }
                onMouseOut={() => setAlertMessage("Hatch your Dappmon.")}
              >
                {loading ? "Revealing..." : "Reveal"}
              </Button>
            ) : isHatching ? (
              <Button disabled className="hatch w-button">
                Hatching...
              </Button>
            ) : (
              <Button
                disabled={loading}
                className="hatch w-button"
                onClick={handleHatch}
                onMouseOver={() => setAlertMessage("Start hatching?")}
                onMouseOut={() => setAlertMessage("Hatch your Dappmon.")}
              >
                {loading ? "Incubating..." : "Hatch"}
              </Button>
            )}
          </ButtonGrid>
        </WhiteContainer>
      </div>
      </div>
    </>
  );
};

export default Hatch;
