import React, { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShop } from "@/hooks/useShop";
import Navigation from "@/components/Navigation";
import AlertContainer from "@/components/AlertContainer";
import ButtonGrid from "@/components/ButtonGrid";
import ShopItem from "@/components/ShopItem";
import GameScreen from "@/components/GameScreen";
import Text from "@/components/ui/Text";
import TextFlex from "@/components/ui/TextFlex";
import Button from "@/components/ui/Button";
import WhiteContainer from "@/components/ui/WhiteContainer";
import { toast } from "sonner";
import { handleError } from "@/lib/error";
import { useAuthenticated } from "@/hooks/useAuthenticated";

export const Shop: FC = () => {
  useAuthenticated();
  const navigate = useNavigate();
  const { shop, refetch } = useShop();
  const [loadingSlots, setLoadingSlots] = useState<number[]>([]);
  const [alertMessage, setAlertMessage] = useState<string>(
    "Purchase Dappmon Items"
  );
  const gameScreenTitle = "Shop";

  const handleBuy = useCallback(
    async (slot: number, itemName: string, quantity: number) => {
      let toastId: string | number | undefined;
      if (!shop) return;

      try {
        setLoadingSlots((prev) => [...prev, slot]);
        await shop.purchaseItemFromShop(slot);
        refetch();
        toastId = toast.success(`Purchased ${quantity}x ${itemName}`);
      } catch (error) {
        handleError(error, toastId);
        toast.error(`Purchase failed.`);
        console.error("Purchase failed", error);
      } finally {
        setLoadingSlots((prev) => prev.filter((s) => s !== slot));
      }
    },
    [shop, refetch]
  );

  const handleMouseOver = (message: string) => {
    setAlertMessage(message);
  };

  const handleMouseOut = () => {
    setAlertMessage("Purchase Dappmon Items");
  };

  return (
    <>
      <div className="app">
        <div className="app-container">
          <Navigation />
          <WhiteContainer>
            <TextFlex>
              <Text className="lg centered">{gameScreenTitle}</Text>
            </TextFlex>
          </WhiteContainer>
          <GameScreen title="Shop">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {(shop?.items || []).map((item, index) => (
                <ShopItem
                  key={index}
                  imageSrc={`/images/items/item-${item.name.toLowerCase()}-0.svg`}
                  name={item.name}
                  owned={Number(item.owned)}
                  stats={[{ label: "Quantity", value: item.quantity.toString() }]}
                  cost={item.price}
                  loading={loadingSlots.includes(item.slot)}
                  onBuy={() => handleBuy(item.slot, item.name, item.quantity)}
                  onMouseOver={() =>
                    handleMouseOver(`Purchase ${item.quantity}x ${item.name}`)
                  }
                  onMouseOut={handleMouseOut}
                />
              ))}
            </div>
            <WhiteContainer className="shadow-inner w-full">
              <div className="text-center">
                <Text className="xxl pb-2">Mint a care package</Text>
                <Button
                  className="care-package w-full"
                  onClick={() => navigate("/start")}
                  onMouseOver={() => setAlertMessage("Mint a new care package.")}
                  onMouseOut={handleMouseOut}
                >
                  <Text className="xl">Mint</Text>
                </Button>
              </div>
            </WhiteContainer>
          </GameScreen>
          <AlertContainer message={alertMessage} />
          <WhiteContainer>
            <ButtonGrid className="back">
              <Button
                className="back dark"
                onClick={() => navigate(-1)}
                onMouseOver={() =>
                  setAlertMessage("Go back to the previous screen")
                }
                onMouseOut={handleMouseOut}
              >
                <img
                  src="/images/ui/ui-arrow-down.svg"
                  alt="Back"
                  width={24}
                  height={24}
                  className="svg white lg"
                />
                <Text>Back</Text>
              </Button>
            </ButtonGrid>
          </WhiteContainer>
        </div>
      </div>
    </>
  );
};

export default Shop;