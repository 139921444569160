import React from 'react';
import Text from './ui/Text';

interface AlertContainerProps {
  message: string;
}

const AlertContainer: React.FC<AlertContainerProps> = ({ message }) => {
  return (
    <div className="alert-container shadow">
      <Text className="xl">{message}</Text>
    </div>
  );
};

export default AlertContainer;
