import mudConfig from "contracts/mud.config";
import { world } from "./world";
import { Hex, PublicClient } from "viem";
import { getNetworkConfig } from "./getNetworkConfig";
import { syncToRecs } from "@latticexyz/store-sync/recs";

export type MUDStateResult = Awaited<ReturnType<typeof setupState>>;

export async function setupState(publicClient: PublicClient) {
  const networkConfig = await getNetworkConfig();

  const { components, latestBlock$, storedBlockLogs$, waitForTransaction } =
    await syncToRecs({
      world,
      config: mudConfig,
      address: networkConfig.worldAddress as Hex,
      publicClient,
      startBlock: BigInt(networkConfig.initialBlockNumber),
      indexerUrl: import.meta.env.VITE_INDEXER_URL,
    });

  return {
    components,
    publicClient,
    latestBlock$,
    networkConfig,
    storedBlockLogs$,
    waitForTransaction,
  };
}
