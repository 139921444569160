import { Hex, getContract, PublicClient, WalletClient } from "viem";
import { ContractWrite } from "@latticexyz/common";
import { Subject, share } from "rxjs";

import { getNetworkConfig } from "./getNetworkConfig";
import { world } from "./world";
import IWorldAbi from "contracts/out/IWorld.sol/IWorld.abi.json";
import DappmonsAbi from "contracts/out/Dappmons.sol/Dappmons.abi.json";
import DappmonPackagesAbi from "contracts/out/DappmonPackages.sol/DappmonPackages.abi.json";
import DappmonItemsAbi from "contracts/out/DappmonItems.sol/DappmonItems.abi.json";
import DappmonSkillsAbi from "contracts/out/DappmonSkills.sol/DappmonSkills.abi.json";

export type SetupNetworkResult = Awaited<ReturnType<typeof setupNetwork>>;

export async function setupNetwork(
  publicClient: PublicClient,
  walletClient: WalletClient
) {
  const networkConfig = await getNetworkConfig();

  /*
   * Create an observable for contract writes that we can
   * pass into MUD dev tools for transaction observability.
   */
  const write$ = new Subject<ContractWrite>();

  /*
   * Create an object for communicating with the deployed World.
   */
  const worldContract = getContract({
    address: networkConfig.worldAddress as Hex,
    abi: IWorldAbi,
    client: { public: publicClient, wallet: walletClient },
  });

  const dappmonsContract = getContract({
    address: await worldContract.read.getDappmonsAddress(),
    abi: DappmonsAbi,
    client: { public: publicClient, wallet: walletClient },
  });

  const packagesContract = getContract({
    address: await worldContract.read.getPackagesAddress(),
    abi: DappmonPackagesAbi,
    client: { public: publicClient, wallet: walletClient },
  });

  const itemsContract = getContract({
    address: await worldContract.read.getItemsAddress(),
    abi: DappmonItemsAbi,
    client: { public: publicClient, wallet: walletClient },
  });

  const skillsContract = getContract({
    address: await worldContract.read.getSkillsAddress(),
    abi: DappmonSkillsAbi,
    client: { public: publicClient, wallet: walletClient },
  });

  return {
    world,
    publicClient,
    walletClient,
    worldContract,
    dappmonsContract,
    packagesContract,
    itemsContract,
    skillsContract,
    write$: write$.asObservable().pipe(share()),
  };
}
