import { useSoundContext } from "@/SoundContext";
import { FC, ReactNode, ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

const Button: FC<ButtonProps> = ({
  children,
  disabled,
  onClick,
  className = "",
  type = "button",
  onMouseOver,
  onMouseOut,
  ...props
}) => {
  const { playClick } = useSoundContext();
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    playClick();

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={handleClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={`button ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
