import React, { FC, ReactNode } from 'react';

interface TextFlexProps {
  children: ReactNode;
  className?: string;
}

const TextFlex: FC<TextFlexProps> = ({ children, className }) => {
  return <div className={`text-flex ${className}`}>{children}</div>;
};

export default TextFlex;
