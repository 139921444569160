// Loader.tsx
import React from "react";

const Loader: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <img
        src="/images/dappmon-mark-black.svg"
        alt="Dappmon Logo"
        className="w-32 h-32 animate-pulse"
      />
      <div className="loader-bar mt-4 w-64 h-2 bg-gray-200 rounded-full relative overflow-hidden">
        <div className="loader-bar-inner absolute top-0 h-full bg-black animate-loader"></div>
      </div>
    </div>
  );
};

export default Loader;
