import React, { useState, useEffect } from "react";
import Button from "./ui/Button";
import { Link, useNavigate } from "react-router-dom";
import TextFlex from "./ui/TextFlex";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { toast } from "sonner";

interface SettingsFormProps {
  settings: {
    sound: boolean;
    color: string;
  };
  onSave: (settings: { sound: boolean; color: string }) => void;
}

const SettingsForm: React.FC<SettingsFormProps> = ({ settings, onSave }) => {
  const { ready, authenticated, logout } = usePrivy();
  const { wallets } = useWallets();
  const navigate = useNavigate();
  const [selectedColor, setSelectedColor] = useState({
    r: 0,
    g: 0,
    b: 0,
  });

  useEffect(() => {
    const color = settings.color.match(/\d+/g)?.map(Number) || [0, 0, 0];
    setSelectedColor({
      r: color[0],
      g: color[1],
      b: color[2],
    });
  }, [settings.color]);

  const handleSoundToggle = () => {
    onSave({ ...settings, sound: !settings.sound });
  };

  const handleColorChange = (colorPart: "r" | "g" | "b", value: number) => {
    const newColor = { ...selectedColor, [colorPart]: value };
    setSelectedColor(newColor);
    const newColorString = `rgb(${newColor.r}, ${newColor.g}, ${newColor.b})`;
    onSave({ ...settings, color: newColorString });
  };

  const handleDisconnectWallet = async () => {
    await logout();
    navigate("/");
  };

  const handleCopyAddress = () => {
    const wallet = wallets[0]; // Assuming there's only one connected wallet
    if (wallet) {
      navigator.clipboard.writeText(wallet.address);
      toast.success("Wallet address copied to clipboard!");
    } else {
      toast.error("Failed to copy wallet address.");
    }
  };

  const shortenAddress = (address: string) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <form className="flex flex-col gap-2">
      <div className="battle-input-wrapper">
        <label htmlFor="sound">Sound</label>
        <Button type="button" onClick={handleSoundToggle}>
          {settings.sound ? "Off" : "On"}
        </Button>
      </div>
      <div className="battle-input-wrapper">
        <label htmlFor="color">Background Color</label>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {["r", "g", "b"].map((colorPart) => (
            <div
              key={colorPart}
              style={{ display: "flex", alignItems: "center" }}
            >
              <label>{colorPart.toUpperCase()}</label>
              <input
                type="range"
                min="0"
                max="255"
                value={selectedColor[colorPart as "r" | "g" | "b"]}
                onChange={(e) =>
                  handleColorChange(
                    colorPart as "r" | "g" | "b",
                    parseInt(e.target.value, 10)
                  )
                }
                style={{
                  marginLeft: "10px",
                  flex: 1,
                  backgroundColor: "black",
                }}
              />
            </div>
          ))}
          <div
            style={{
              backgroundColor: `rgb(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b})`,
              width: "40px",
              height: "40px",
              margin: "10px auto",
              border: "2px solid black",
            }}
          />
        </div>
      </div>
      {ready && authenticated && wallets.length > 0 && (
        <div className="battle-input-wrapper">
          <label htmlFor="wallet">Wallet Address</label>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <span style={{ fontSize: "1.1rem" }}>
              {shortenAddress(wallets[0].address)}
            </span>
            <div className="pt-2 w-full">
            <Button type="button" className="w-full" onClick={handleCopyAddress}>
              Copy
            </Button>
            </div>
          </div>
        </div>
      )}
      {ready && authenticated && (
        <div className="battle-input-wrapper">
          <label htmlFor="disconnect">Disconnect</label>
          <Button type="button" onClick={handleDisconnectWallet}>
            Disconnect Wallet
          </Button>
        </div>
      )}
      <div className="battle-input-wrapper">
        <TextFlex>
          <Link className="mx-auto" to="/terms">Terms & Conditions</Link>
          <Link className="mx-auto" to="/policy">Privacy Policy</Link>
        </TextFlex>
      </div>
    </form>
  );
};

export default SettingsForm;
