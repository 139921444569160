import React, { FC } from 'react';
import Text from './ui/Text';
import TextFlex from './ui/TextFlex';
import WhiteContainer from './ui/WhiteContainer';

const obfuscateAddress = (address: string) => {
  if (address.length < 10) return address;
  return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
};

interface ProfileStatsProps {
  attack: number;
  defense: number;
  tech: number;
  techDefense: number;
  speed: number;
  type: string;
  stage: string;
  weight: string;
  basePower: string;
  version: string;
  age: string;
  hatchDate: string;
  walletAddress: string;
  evoTimer: string;
}

const ProfileStats: FC<ProfileStatsProps> = ({
  attack,
  defense,
  tech,
  techDefense,
  speed,
  type,
  stage,
  weight,
  basePower,
  version,
  age,
  hatchDate,
  walletAddress,
  evoTimer,
}) => {
  return (
    <div className="stat-wrapper">
      <TextFlex>
        <WhiteContainer className="w-full">
          <div className="text-wrapper">
            <TextFlex className="stat">
              <Text className="lg">Attack</Text>
              <Text className="xl bold">{attack}</Text>
            </TextFlex>
            <TextFlex className="stat">
              <Text className="lg">Defense</Text>
              <Text className="xl bold">{defense}</Text>
            </TextFlex>
            <TextFlex className="stat">
              <Text className="lg">Tech</Text>
              <Text className="xl bold">{tech}</Text>
            </TextFlex>
            <TextFlex className="stat">
              <Text className="lg">TechDef</Text>
              <Text className="xl bold">{techDefense}</Text>
            </TextFlex>
            <TextFlex className="stat">
              <Text className="lg">Speed</Text>
              <Text className="xl bold">{speed}</Text>
            </TextFlex>
          </div>
        </WhiteContainer>
        <WhiteContainer className="w-full">
          <div className="text-wrapper stat">
            <TextFlex className="stat">
              <Text className="lg">Type</Text>
              <Text className="xl bold">{type}</Text>
            </TextFlex>
            <TextFlex className="stat">
              <Text className="lg">Stage</Text>
              <Text className="xl bold">{stage}</Text>
            </TextFlex>
            <TextFlex className="stat">
              <Text className="lg">Weight</Text>
              <Text className="xl bold">{weight}</Text>
            </TextFlex>
            <TextFlex className="stat">
              <Text className="lg">Power</Text>
              <Text className="xl bold">{basePower}</Text>
            </TextFlex>
            <TextFlex className="stat">
              <Text className="lg">Version</Text>
              <Text className="xl bold">{version}</Text>
            </TextFlex>
          </div>
        </WhiteContainer>
      </TextFlex>
      <WhiteContainer className="sub">
        <div className="text-wrapper stat">
          <TextFlex className="stat">
            <Text className="lg">Age</Text>
            <Text className="xl bold">{age}</Text>
          </TextFlex>
          <TextFlex className="stat">
            <Text className="lg">Birthdate</Text>
            <Text className="xl bold">{hatchDate}</Text>
          </TextFlex>
          <TextFlex className="stat">
            <Text className="lg">Owner</Text>
            <Text className="xl bold">{obfuscateAddress(walletAddress)}</Text>
          </TextFlex>
          <TextFlex className="stat">
            <Text className="lg">Stage Timer</Text>
            <Text className="xl bold">{evoTimer}</Text>
          </TextFlex>
        </div>
      </WhiteContainer>
    </div>
  );
};

export default ProfileStats;
