import { useMUD } from "../MUDContext";
import { LoadingProgress } from "./LoadingProgress";

export function LoadingScreen({ children }: { children: React.ReactNode }) {
  const mud = useMUD();

  if (mud) {
    return <LoadingProgress>{children}</LoadingProgress>;
  }

  return (
    <div className="w-full h-full absolute flex content-center items-center justify-center bg-white z-50 flex-col">
      <h3>Loading...</h3>
    </div>
  );
}
