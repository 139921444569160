import React, { FC } from "react";
import Text from "./ui/Text";
import TextFlex from "./ui/TextFlex";

interface ProfileHeaderProps {
  dappmon: number;
  name: string;
  hp: number;
}

const ProfileHeader: FC<ProfileHeaderProps> = ({ dappmon, name, hp }) => {
  const imgSrc = `/images/dappmon/id/${dappmon}/${dappmon}-idle-0.svg`;

  return (
    <div className="dappmon-profile-header border-bottom">
      <div className="header-wrapper">
        <TextFlex className="">
          <img
            src={imgSrc}
            alt="Dappmon"
            width={48}
            height={48}
            className="svg"
          />
          <TextFlex>
          <Text className="xl">ID: {dappmon.toString()}</Text>
          <Text className="xl">{name}</Text>
          </TextFlex>
          </TextFlex>
        <div className="hp-wrapper">
          <div className="text-flex">
            <Text className="lg">HP</Text>
            <Text className="xl bold">{hp}</Text>
          </div>
          <div className="hp-bar-wrapper">
            <div className="hp-bar player"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
