import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonGrid from './ButtonGrid';
import Button from './ui/Button';
import Text from './ui/Text';

export const CareActionButtons: FC<{
  loading?: boolean;
  dappmonId?: bigint;
  onTrain?: () => void;
  medicineCount?: bigint;
  onMedicine?: () => void;
  bandageCount?: bigint;
  onBandage?: () => void;
  foodCount?: bigint;
  onFood?: () => void;
  proteinCount?: bigint;
  onProtein?: () => void;
  setAlertMessage: (message: string) => void;
  isPublicView: boolean;
}> = ({
  loading,
  onTrain,
  medicineCount,
  onMedicine,
  bandageCount,
  onBandage,
  foodCount,
  onFood,
  proteinCount,
  onProtein,
  setAlertMessage,
  isPublicView,
  dappmonId,
}) => {
  const [tab, setTab] = useState<'main' | 'care' | 'feed'>('main');
  const navigate = useNavigate();

  const handleMouseOver = (message: string) => {
    setAlertMessage(message);
  };

  const handleMouseOut = () => {
    setAlertMessage('Select an action');
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    message: string,
    action: () => void
  ) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage('Select an action');
    }, 2000);
    action();
  };

  const handleViewNFT = () => {
    if (dappmonId) {
      const url = `https://magiceden.io/item-details/base/0x066d1050784e0d5d0f12dba1b5b2ab41c51c742f/${dappmonId}`;
      window.open(url, '_blank');
    }
  };

  // Render buttons for the public view
  if (isPublicView) {
    return (
      <ButtonGrid>
        <Button
          className="view"
          onClick={handleViewNFT}
          onMouseOver={() => handleMouseOver('View this Dappmon NFT')}
          onMouseOut={handleMouseOut}
        >
          View NFT
        </Button>
        <Button
          className="dark cancel"
          onClick={() => navigate(-1)}
          onMouseOver={() => handleMouseOver('Cancel and go back')}
          onMouseOut={handleMouseOut}
        >
          Cancel
        </Button>
      </ButtonGrid>
    );
  }

  // Render buttons for the private view
  return (
    <>
      {loading ? (
        <ButtonGrid className="main">
          <Button>
            <Text>Loading...</Text>
          </Button>
        </ButtonGrid>
      ) : (
        <>
          {tab === 'main' && (
            <ButtonGrid className="main">
              <Button
                className="feed"
                onClick={() => setTab('feed')}
                onMouseOver={() => handleMouseOver('Feed your Dappmon')}
                onMouseOut={handleMouseOut}
              >
                <img
                  src="/images/item-protein-1.svg"
                  alt="Feed"
                  width={24}
                  height={24}
                  className="svg lg"
                />
                <Text>Feed</Text>
              </Button>
              <Button
                className="train"
                onClick={(event) => handleClick(event, 'Training...', onTrain!)}
                onMouseOver={() => handleMouseOver('Train your Dappmon')}
                onMouseOut={handleMouseOut}
              >
                <img
                  src="/images/ui-training-0.svg"
                  alt="Train"
                  width={24}
                  height={24}
                  className="svg lg"
                />
                <Text>Train</Text>
              </Button>
              <Button
                className="care"
                onClick={() => setTab('care')}
                onMouseOver={() => handleMouseOver('Take care of your Dappmon')}
                onMouseOut={handleMouseOut}
              >
                <img
                  src="/images/item-medicine-0.svg"
                  alt="Care"
                  width={24}
                  height={24}
                  className="svg lg"
                />
                <Text>Care</Text>
              </Button>
            </ButtonGrid>
          )}
          {tab === 'care' && (
            <ButtonGrid className="care">
              <Button
                className="medicine"
                onClick={(event) => handleClick(event, 'Giving medicine...', onMedicine!)}
                onMouseOver={() => handleMouseOver('Give medicine to your Dappmon')}
                onMouseOut={handleMouseOut}
              >
                <img
                  src="/images/item-medicine-0.svg"
                  alt="Medicine"
                  width={24}
                  height={24}
                  className="svg lg"
                />
                <div className="damage-box">
                  <Text className="lg">{Number(medicineCount)}</Text>
                </div>
                <Text>Medicine</Text>
              </Button>
              <Button
                className="back dark"
                onClick={() => setTab('main')}
                onMouseOver={() => handleMouseOver('Go back to main')}
                onMouseOut={handleMouseOut}
              >
                <img
                  src="/images/ui-arrow-down.svg"
                  alt="Back"
                  width={24}
                  height={24}
                  className="svg white lg"
                />
                <Text>Back</Text>
              </Button>
              <Button
                className="bandage"
                onClick={(event) => handleClick(event, 'Applying bandage...', onBandage!)}
                onMouseOver={() => handleMouseOver('Apply a bandage')}
                onMouseOut={handleMouseOut}
              >
                <div className="damage-box">
                  <Text className="lg">{Number(bandageCount)}</Text>
                </div>
                <img
                  src="/images/item-bandage-0.svg"
                  alt="Bandage"
                  width={24}
                  height={24}
                  className="svg lg"
                />
                <Text>Bandage</Text>
              </Button>
            </ButtonGrid>
          )}
          {tab === 'feed' && (
            <ButtonGrid className="feed">
              <Button
                className="food"
                onClick={(event) => handleClick(event, 'Feeding...', onFood!)}
                onMouseOver={() => handleMouseOver('Feed your Dappmon')}
                onMouseOut={handleMouseOut}
              >
                <img
                  src="/images/item-food-0.svg"
                  alt="Food"
                  width={24}
                  height={24}
                  className="svg lg"
                />
                <div className="damage-box">
                  <Text className="lg">{Number(foodCount)}</Text>
                </div>
                <Text>Food</Text>
              </Button>
              <Button
                className="back dark"
                onClick={() => setTab('main')}
                onMouseOver={() => handleMouseOver('Go back to main')}
                onMouseOut={handleMouseOut}
              >
                <img
                  src="/images/ui-arrow-down.svg"
                  alt="Back"
                  width={24}
                  height={24}
                  className="svg white lg"
                />
                <Text>Back</Text>
              </Button>
              <Button
                className="protein"
                onClick={(event) => handleClick(event, 'Giving protein...', onProtein!)}
                onMouseOver={() => handleMouseOver('Give protein to your Dappmon')}
                onMouseOut={handleMouseOut}
              >
                <img
                  src="/images/item-protein-1.svg"
                  alt="Protein"
                  width={24}
                  height={24}
                  className="svg lg"
                />
                <div className="damage-box">
                  <Text className="lg">{Number(proteinCount)}</Text>
                </div>
                <Text>Protein</Text>
              </Button>
            </ButtonGrid>
          )}
        </>
      )}
    </>
  );
};
