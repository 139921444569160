import React from 'react';

interface AppColorProps {
  color: string;
}

const AppColor: React.FC<AppColorProps> = ({ color }) => {
  const overlayStyle: React.CSSProperties = {
    mixBlendMode: 'overlay',
    zIndex: 9999,
    pointerEvents: 'none',
    backgroundColor: color,
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0%',
    bottom: '0%',
    left: '0%',
    right: '0%'
  };

  return <div style={overlayStyle} />;
};

export default AppColor;
