import { toast } from "sonner";
import {
  BaseError,
  ContractFunctionExecutionError,
  ContractFunctionRevertedError,
  TransactionExecutionError,
} from "viem";

export function handleError(err: unknown, toastId?: string | number) {
  console.log(err);

  if (err instanceof BaseError) {
    const error = err.walk();

    if (error instanceof ContractFunctionRevertedError) {
      showError(error.data?.args?.toString() ?? "unknown error");
    } else {
      const txError = err.walk((e) => e instanceof TransactionExecutionError);

      if (txError instanceof TransactionExecutionError) {
        showError(txError.shortMessage ?? "unknown error");
      } else {
        showError((err as ContractFunctionExecutionError).shortMessage || "unknown error");
      }
    }
  } else {
    showError((err as Error).message || "unknown error");
  }

  function showError(message: string) {
    toast.error(message, { id: toastId });
  }
}