import React from "react";
import Navigation from "../components/Navigation";
import WhiteContainer from "@/components/ui/WhiteContainer";
import TextFlex from "@/components/ui/TextFlex";
import Text from "@/components/ui/Text";
import TermsContent from "../components/TermsContent";
import Button from "@/components/ui/Button";
import ButtonGrid from "@/components/ButtonGrid";
import GameScreen from "@/components/GameScreen";
import AlertContainer from "@/components/AlertContainer";
import { useNavigate } from "react-router-dom"; // Ensure to import useNavigate

export const Terms: React.FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <>
    <div className="app">
      <div className="app-container">
        <Navigation />
        <WhiteContainer>
          <TextFlex>
            <Text className="lg centered">Terms & Conditions</Text>
          </TextFlex>
        </WhiteContainer>
        <GameScreen title="Terms">
          <WhiteContainer>
            <TermsContent />
          </WhiteContainer>
        </GameScreen>
        <AlertContainer message="Terms & Conditions" />
        <WhiteContainer>
          <ButtonGrid className="back">
            <Button className="back dark" onClick={() => navigate(-1)}>
              <img
                src="/images/ui/ui-arrow-down.svg"
                alt="Back"
                width={24}
                height={24}
                className="svg white lg"
              />
              <Text>Back</Text>
            </Button>
          </ButtonGrid>
        </WhiteContainer>
      </div>
      </div>
    </>
  );
};

export default Terms;
