import { FC } from 'react';

interface TextProps {
  children: React.ReactNode;
  className?: string;
}

const Text: FC<TextProps> = ({ children, className = '' }) => (
  <div className={`text ${className}`}>{children}</div>
);

export default Text;
