import { FC, useEffect, useState } from "react";
import Navigation from "@/components/Navigation";
import GameScreen from "@/components/GameScreen";
import AlertContainer from "@/components/AlertContainer";
import WhiteContainer from "@/components/ui/WhiteContainer";
import ButtonGrid from "@/components/ButtonGrid";
import Button from "@/components/ui/Button";
import Text from "@/components/ui/Text";
import { useDappmons } from "@/hooks/useDappmons";
import { usePackages } from "@/hooks/usePackages";
import { DAPPMON_NAMES, STAGE_NAMES, TYPE_NAMES } from "@/constants";
import { useNavigate } from "react-router-dom";
import DappmonProfile from "@/components/DappmonProfile";
import TextFlex from "@/components/ui/TextFlex";
import { toast } from "sonner";
import { useAuthenticated } from "@/hooks/useAuthenticated";

export const Dappmons: FC = () => {
  useAuthenticated();
  const { dappmons } = useDappmons();
  const { packages } = usePackages();
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState<string>("Select a Dappmon!");
  const [quantityCarePackages, setQuantityCarePackages] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    if (packages) {
      setQuantityCarePackages(packages.length);
    }
  }, [packages]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredDappmons = (dappmons || []).filter((dappmon) => {
    const name = dappmon.dappmon ? DAPPMON_NAMES[dappmon.dappmon] : "";
    return (
      dappmon.dappmonId.toString().includes(searchTerm) ||
      name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });


  const handleDappmonClick = (dappmonId: bigint, stage: string | undefined) => {
    if (stage === "Egg" || stage === undefined) {
      navigate(`/dappmons/${dappmonId}/hatch`);
    } else {
      navigate(`/dappmons/${dappmonId}`);
    }
  };

  const handleOpenCarePackages = () => {
    navigate("/open");
    toast.success("Open care packages!");
  };

  return (
    <>
    <div className="app">
      <div className="app-container">
        <Navigation />
        <WhiteContainer>
          <Text className="lg centered">My Dappmon</Text>
        </WhiteContainer>
        <GameScreen title="My Dappmon">
          <div className="tabs-menu white-container sticky top-0 z-20 w-full">
            <div className="search-bar w-full">
              <input
                className="input text w-input w-full sticky top-0"
                maxLength={256}
                placeholder="Enter Dappmon ID or Name to search"
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          {quantityCarePackages > 0 && (
            <WhiteContainer className="max-w-md">
              <TextFlex className="care-packages">
                <Text className="xl bold">Care Packages</Text>
                <Text className="xl">Owned: {quantityCarePackages}</Text>
                <Button
                  className="w-full"
                  onClick={handleOpenCarePackages}
                  onMouseOver={() => setAlertMessage("Open care packages")}
                  onMouseOut={() => setAlertMessage("Select a Dappmon!")}
                >
                  Open
                </Button>
              </TextFlex>
            </WhiteContainer>
          )}

          <div className="flex flex-wrap -mx-2">
            {filteredDappmons.map((dappmon) => {
              const stage = STAGE_NAMES[Number(dappmon.stage?.value)];
              const hasPooped = dappmon.poopCount > 0;

              return (
                <div
                  key={dappmon.dappmonId.toString()}
                  className="w-full sm:w-1/1 md:w-1/1 lg:w-1/2 pl-2 pr-2"
                >
                  <DappmonProfile
                    dappmonId={dappmon.dappmonId}
                    dappmon={dappmon.dappmon}
                    name={dappmon.dappmon ? DAPPMON_NAMES[dappmon.dappmon] : ""}
                    hp={dappmon.hp}
                    attack={dappmon.attack}
                    defense={dappmon.defense}
                    tech={dappmon.tech}
                    techDefense={dappmon.techDefense}
                    speed={dappmon.speed}
                    type={TYPE_NAMES[dappmon.dappmonType]}
                    stage={stage}
                    weight={`${dappmon.weight.toString()}kg`}
                    basePower={dappmon.basePower || 1}
                    version="1.0" // Placeholder
                    strength={
                      dappmon.strength ? Number(dappmon.strength) : undefined
                    }
                    hunger={dappmon.hunger ? Number(dappmon.hunger) : undefined}
                    canClean={hasPooped}
                    canSleep={dappmon.isTired}
                    sick={dappmon.isSick}
                    hurt={dappmon.isInjured}
                    tired={dappmon.isTired}
                    dead={dappmon.hasDied}
                    canEvolve={dappmon.canEvolve}
                    onClick={() => handleDappmonClick(dappmon.dappmonId, stage)}
                    onMouseOver={() =>
                      setAlertMessage(
                        `Select #${dappmon.dappmonId.toString()} ${DAPPMON_NAMES[dappmon.dappmon]}?`
                      )
                    }
                    onMouseOut={() => setAlertMessage("Select a Dappmon!")}
                  />
                </div>
              );
            })}
          </div>
        </GameScreen>
        <AlertContainer message={alertMessage} />
        <WhiteContainer>
          <ButtonGrid className="back">
            <Button
              className="back dark"
              onClick={() => navigate(-1)}
              onMouseOver={() => setAlertMessage("Go back")}
              onMouseOut={() => setAlertMessage("Select a Dappmon!")}
            >
              <img
                src="/images/ui/ui-arrow-down.svg"
                alt="Back"
                width={24}
                height={24}
                className="svg white lg"
              />
              <Text>Back</Text>
            </Button>
          </ButtonGrid>
        </WhiteContainer>
      </div>
      </div>
    </>
  );
};

export default Dappmons;
