/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Text from "./ui/Text";
import WhiteContainer from "./ui/WhiteContainer";

const PrivacyContent: React.FC = () => {
  return (
    <WhiteContainer>
        <div className="left-align">
      <Text className="xl bold">Privacy Policy</Text>
      <Text className="md">Last Updated: [July 7, 2024]</Text>
      <br />
      <Text className="lg bold">1. Introduction</Text>
      <Text className="lg">
        This Privacy Policy explains how Dappmon, owned and operated by Strawberry Holding Company, LLC, collects, uses, and discloses information about you. By using our services, you agree to the collection and use of information in accordance with this policy.
      </Text>
<br />
      <Text className="lg bold">2. Information We Collect</Text>
      <Text className="lg">
        We collect the following types of information:
        <ul>
          <li>Blockchain Data: Information that is publicly available on the blockchain, such as your wallet address and transactions.</li>
          <li>Account Information: Information you provide when you create an account, such as your username and email address.</li>
        </ul>
      </Text>
      <br />
      <Text className="lg bold">3. How We Use Your Information</Text>
      <Text className="lg">
        We use the information we collect for various purposes, including:
        <ul>
          <li>To provide and maintain our service</li>
          <li>To notify you about changes to our service</li>
          <li>To provide customer support</li>
          <li>To monitor the usage of our service</li>
          <li>To detect, prevent, and address technical issues</li>
        </ul>
      </Text>
      <br />
      <Text className="lg bold">4. Data Security</Text>
      <Text className="lg">
        The security of your data is important to us. We use commercially acceptable means to protect your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
      </Text>
      <br />
      <Text className="lg bold">5. Data Sharing and Disclosure</Text>
      <Text className="lg">
        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.
      </Text>
      <br />
      <Text className="lg bold">6. Third-Party Services</Text>
      <Text className="lg">
        Our service may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.
      </Text>
      <br />
      <Text className="lg bold">7. Children's Privacy</Text>
      <Text className="lg">
        Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If we become aware that we have collected personal data from a child under 13 without verification of parental consent, we take steps to remove that information from our servers.
      </Text>
      <br />
      <Text className="lg bold">8. Changes to This Privacy Policy</Text>
      <Text className="lg">
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
      </Text>
      </div>
    </WhiteContainer>
  );
};

export default PrivacyContent;
