import { FC } from "react";
import Button from "./ui/Button";
import WhiteContainer from "./ui/WhiteContainer";

interface DeadModalProps {
  onMintAnother: () => void;
  onClose: () => void;
}

const DeadModal: FC<DeadModalProps> = ({ onMintAnother, onClose }) => {
  return (
    <div className="absolute inset-0 z-50 flex justify-center bg-black bg-opacity-50 p-4 md:p-8">
      <WhiteContainer className="relative max-w-sm max-h-64 md:max-w-md p-8 flex flex-col justify-center items-center">
        <button
          onClick={onClose}
          className="absolute top-2 right-2"
        >
          ✕
        </button>
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-4">RIP</h2>
        <p className="text-center mb-6">
          Your Dappmon has perished.
        </p>
        <Button onClick={onMintAnother} className="w-full max-h-16">
          Mint a Dappmon?
        </Button>
      </WhiteContainer>
    </div>
  );
};

export default DeadModal;
