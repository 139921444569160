import { createContext, ReactNode, useContext, useState } from "react";
import useSound from "use-sound";

export type Sound = {
  playClick: () => void;
  playHappy: () => void;
  playSad: () => void;
  setVolume: (volume: number) => void;
};

const SoundContext = createContext<Sound>({
  playClick: () => {},
  playHappy: () => {},
  playSad: () => {},
  setVolume: () => {},
});

type Props = {
  children: ReactNode;
};

export const SoundContextProvider = ({ children }: Props) => {
  const [volume, setVolume] = useState(0.15);
  const [playClick] = useSound("/sounds/click.wav", { volume });
  const [playHappy] = useSound("/sounds/happy.wav", { volume });
  const [playSad] = useSound("/sounds/sad.wav", { volume });

  return (
    <SoundContext.Provider
      value={{
        playClick,
        playHappy,
        playSad,
        setVolume,
      }}
    >
      {children}
    </SoundContext.Provider>
  );
};

export const useSoundContext = () => {
  return useContext(SoundContext);
};
