import React, { FC, useState } from "react";
import { toast } from "sonner";
import Text from "./ui/Text";
import Button from "./ui/Button";
import { useMUD } from "@/MUDContext";

interface TransferDappmonProps {
  dappmonId: string;
  onCancel: () => void;
}

const TransferDappmon: FC<TransferDappmonProps> = ({ dappmonId, onCancel }) => {
  const mud = useMUD();
  const [address, setAddress] = useState("");
  const [confirmTransfer, setConfirmTransfer] = useState(false);

  const handleTransfer = async () => {
    if (!/^0x[a-fA-F0-9]{40}$/.test(address)) {
      toast.error("Invalid wallet address");
      return;
    }

    try {
      await mud?.systemCalls?.transfer(BigInt(dappmonId), address as `0x${string}`);
      toast.success("Dappmon transferred successfully!");
      onCancel();
    } catch (error) {
      toast.error("Error transferring Dappmon. Please check the address and try again.");
    }
  };

  return (
    <div className="p-4">
      {!confirmTransfer ? (
        <div className="flex flex-col gap-2 items-center w-full">
          <input
            className="input w-full border border-gray-300 rounded"
            type="text"
            placeholder="Enter wallet address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <div className="flex space-x-2 w-full">
            <Button className="w-full" onClick={() => setConfirmTransfer(true)}>
              Transfer
            </Button>
            <Button className="w-full" onClick={onCancel}>Cancel</Button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex items-center w-full">
            <Text className="xxl">Transfer:</Text>
            <Text>Are you sure? This is irreversible.</Text>
          
        
            <Button className="w-full flex-grow dark" onClick={handleTransfer}>
              Yes
            </Button>
            <Button className="w-full flex-grow" onClick={() => setConfirmTransfer(false)}>No</Button>
        </div>
        </>
      )}
    </div>
  );
};

export default TransferDappmon;
