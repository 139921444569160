import { useMUD } from "@/MUDContext";
import { useQuery } from "@tanstack/react-query";
import { fetchDappmon } from "./useDappmon";

export const useDappmons = () => {
  const mud = useMUD();
  const {
    data: dappmons,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["dappmons"],
    queryFn: async () => {
      if (!mud?.systemCalls) return [];

      const dappmonIds = await mud.systemCalls.getDappmonIds();

      return Promise.all(
        dappmonIds.map((dappmonId: bigint) => fetchDappmon(mud, dappmonId))
      );
    },
    enabled: !!mud?.systemCalls,
    refetchOnWindowFocus: "always",
  });

  return {
    dappmons,
    isLoading,
    isFetching,
    refetch,
  };
};
