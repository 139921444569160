import React, { FC, useEffect, useState } from 'react';

interface HatchingAnimationProps {
  className?: string;
}

const HatchingAnimation: FC<HatchingAnimationProps> = ({ className }) => {
  const [frameIndex, setFrameIndex] = useState(0);

  const frames = [
    `/images/hatching/hatch-0.svg`,
    `/images/hatching/hatch-1.svg`,
    `/images/hatching/hatch-2.svg`,
    `/images/hatching/hatch-3.svg`,
    `/images/hatching/hatch-4.svg`,
    `/images/hatching/hatch-5.svg`,
    `/images/hatching/hatch-6.svg`
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFrameIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex >= frames.length) {
          return 0; // Reset to start
        }
        return nextIndex;
      });
    }, 450);

    return () => {
      clearInterval(interval);
    };
  }, [frames.length]);

  return (
    <div className={`responsive-canvas ${className}`}>
      {frames.length > 0 && (
        <div className="hatching-animation">
          <img
            src={frameIndex < 5 ? frames[frameIndex] : frames[5]}
            alt="Hatching animation"
            className={frameIndex >= 5 ? "hatching-frame" : ""}
          />
          {frameIndex >= 5 && (
            <img
              src={frames[6]}
              alt="Hatching final frame"
              className="hatching-final-frame"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default HatchingAnimation;
