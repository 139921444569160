import { FC } from "react";

interface CareLeftProps {
  selectedItem?: "food" | "protein" | "bandage" | "medicine" | null;
}

const CareLeft: FC<CareLeftProps> = ({ selectedItem }) => {
  return (
    <div className="mx-auto md:w-52 sm:w-52 flex flex-col justify-end items-center">
      {selectedItem && (
        <img
          src={`/images/items/item-${selectedItem}-0.svg`}
          alt={selectedItem}
          className="max-w-24"
        />
      )}
    </div>
  );
};

export default CareLeft;
