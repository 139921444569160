import { FC, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Toaster } from "sonner";
import AppColor from "@/components/ui/AppColor";

export const Root: FC = () => {
  const [color, setColor] = useState('rgb(0, 0, 0)'); // Default color

  useEffect(() => {
    const savedSettings = localStorage.getItem('settings');
    if (savedSettings) {
      const parsedSettings = JSON.parse(savedSettings);
      if (parsedSettings.color) {
        setColor(parsedSettings.color);
      }
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const savedSettings = localStorage.getItem('settings');
      if (savedSettings) {
        const parsedSettings = JSON.parse(savedSettings);
        if (parsedSettings.color) {
          setColor(parsedSettings.color);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div>
      <AppColor color={color} />
      <Toaster 
        position="top-center" 
        toastOptions={{
          style: {
            textAlign: 'center',
            backgroundColor: 'rgb(255,255,255)',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '12px',
            border: 'solid var(--spacing--1)',
            padding: '1.5rem',
            fontFamily: '"Silkscreen", sans-serif',
            fontWeight: 700,
            fontSize: '1.25rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }} 
        richColors
      />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: '100vh' }}>
        <Outlet />
      </div>
    </div>
  );
};

export default Root;
