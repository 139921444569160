import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "@/components/Navigation";
import Text from "@/components/ui/Text";
import TextFlex from "@/components/ui/TextFlex";
import GameScreen from "@/components/GameScreen";
import WhiteContainer from "@/components/ui/WhiteContainer";
import SettingsForm from "@/components/SettingsForm";
import AlertContainer from "@/components/AlertContainer";
import ButtonGrid from "@/components/ButtonGrid";
import Button from "@/components/ui/Button";
import { useAuthenticated } from "@/hooks/useAuthenticated";
import { useSoundContext } from "@/SoundContext";

export const Settings: React.FC = () => {
  useAuthenticated();
  const navigate = useNavigate();
  const { setVolume } = useSoundContext();
  const [settings, setSettings] = useState({
    sound: true,
    color: "",
  });
  const [alertMessage, setAlertMessage] = useState<string>(
    "Select your settings."
  );

  useEffect(() => {
    const savedSettings = localStorage.getItem("settings");
    if (savedSettings) {
      const parsedSettings = JSON.parse(savedSettings);
      setSettings(parsedSettings);
      setVolume(parsedSettings.sound ? 0.15 : 0);
    }
  }, [setVolume]);

  const handleSave = (newSettings: typeof settings) => {
    setSettings(newSettings);
    localStorage.setItem("settings", JSON.stringify(newSettings));
    setVolume(newSettings.sound ? 0.15 : 0);
    setAlertMessage("Settings saved successfully.");
    window.dispatchEvent(new Event("storage"));
  };

  const handleMouseOver = (message: string) => {
    setAlertMessage(message);
  };

  const handleMouseOut = () => {
    setAlertMessage("Select your settings.");
  };

  return (
    <div className="app">
      <div className="app-container">
        <Navigation />
        <WhiteContainer>
          <TextFlex>
            <Text className="lg centered">Settings</Text>
          </TextFlex>
        </WhiteContainer>
        <GameScreen title="Settings">
          <SettingsForm settings={settings} onSave={handleSave} />
        </GameScreen>
        <AlertContainer message={alertMessage} />
        <WhiteContainer>
          <ButtonGrid className="back">
            <Button
              className="back dark"
              onClick={() => navigate(-1)}
              onMouseOver={() =>
                handleMouseOver("Go back to the previous screen")
              }
              onMouseOut={handleMouseOut}
            >
              <img
                src="/images/ui/ui-arrow-down.svg"
                alt="Back"
                width={24}
                height={24}
                className="svg white lg"
              />
              <Text>Back</Text>
            </Button>
          </ButtonGrid>
        </WhiteContainer>
      </div>
    </div>
  );
};

export default Settings;
