import React, { FC, useEffect, useState } from "react";

interface PoopAnimationProps {
  pooped: boolean;
}

const PoopAnimation: FC<PoopAnimationProps> = ({ pooped }) => {
  const [image, setImage] = useState<string>("/images/ui/ui-poop-0.svg");
  const [animationFrame, setAnimationFrame] = useState<number>(0);

  useEffect(() => {
    if (pooped) {
      const interval = setInterval(() => {
        setAnimationFrame((prevFrame) => (prevFrame === 0 ? 1 : 0));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [pooped]);

  useEffect(() => {
    setImage(`/images/ui/ui-poop-${animationFrame}.svg`);
  }, [animationFrame]);

  return (
    <div className="w-full">
      {pooped && <img src={image} alt="Poop" className=""/>}
    </div>
  );
};

export default PoopAnimation;
