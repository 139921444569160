import { FC } from 'react';

interface WhiteContainer {
  children: React.ReactNode;
  className?: string;
}

const WhiteContainer: FC<WhiteContainer> = ({ children, className = '' }) => (
  <div className={`white-container shadow-inner ${className}`}>{children}</div>
);

export default WhiteContainer;
