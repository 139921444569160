import { useRouteError, useNavigate } from "react-router-dom";
import Navigation from "@/components/Navigation";
import GameScreen from "@/components/GameScreen";
import Button from "@/components/ui/Button";
import ButtonGrid from "@/components/ButtonGrid";
import Text from "@/components/ui/Text";
import TextFlex from "@/components/ui/TextFlex";
import WhiteContainer from "@/components/ui/WhiteContainer";

export const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="app">
      <div className="app-container">
        <Navigation />
        <WhiteContainer>
          <TextFlex>
            <Text className="lg centered bold">404 VOID!</Text>
          </TextFlex>
        </WhiteContainer>
        <GameScreen title="Error">
          <Text className="bg-slate-400 xxl bold">Error: </Text>
          <Text className="xxl">
            {(error as { statusText: string }).statusText ||
            (error as Error).message}
          </Text>
        </GameScreen>
        <WhiteContainer>
          <ButtonGrid className="error">
            <Button className="button back dark" onClick={handleBack}>
              <img
                src="/images/ui-arrow-down.svg"
                loading="lazy"
                alt="Back"
                className="svg white lg"
                width={24}
                height={24}
              />
              <Text>Back</Text>
            </Button>
          </ButtonGrid>
        </WhiteContainer>
      </div>
      </div>
    </>
  );
};
