import { PrivyProvider as BasePrivyProvider } from "@privy-io/react-auth";

import { PropsWithChildren } from "react";
import { chain } from "./mud/supportedChains";
import { Chain } from "viem";

export const PrivyProvider = ({ children }: PropsWithChildren) => {
  return (
    <BasePrivyProvider
      appId={import.meta.env.VITE_PRIVY_APP_ID}
      config={{
        appearance: {
          walletList: ["coinbase_wallet", "metamask", "wallet_connect"],
        },
        externalWallets: {
          coinbaseWallet: {
            connectionOptions: "all",
          },
        },
        defaultChain: chain as Chain,
        supportedChains: [chain as Chain],
      }}
    >
      {children}
    </BasePrivyProvider>
  );
};
