import React, { FC, useState } from "react";
import Navigation from "../components/Navigation";
import GameScreen from "../components/GameScreen";
import AlertContainer from "../components/AlertContainer";
import WhiteContainer from "@/components/ui/WhiteContainer";
import Text from "../components/ui/Text";
import TextFlex from "../components/ui/TextFlex";
import { useParams } from "react-router-dom";
import { useDappmon } from "@/hooks/useDappmon";
import { DAPPMON_NAMES, STAGE_NAMES, TYPE_NAMES } from "@/constants";
import { formatDistanceStrict } from "date-fns";
import ProfileHeader from "../components/ProfileHeader";
import ProfileStats from "../components/ProfileStats";
import ProfileActionButtons from "../components/ProfileActionButtons";
import { toast } from "sonner";

export const Profile: FC = () => {
  const { dappmonId } = useParams<{ dappmonId: string }>();
  const { dappmon, isOwner, error } = useDappmon(
    dappmonId ? BigInt(dappmonId) : undefined
  );
  const [alertMessage, setAlertMessage] = useState<string>("Select an action");

  if (!dappmonId) {
    toast.error("No Dappmon ID provided.");
    return <div>Error: No Dappmon ID provided.</div>;
  }

  if (error) {
    if (error instanceof Error) {
      toast.error(`Error: ${error.message}`);
    } else {
      toast.error("An unknown error occurred.");
    }
    return <div>Error: {error.message}</div>;
  }

  if (!dappmon) {
    return error;
  }

  const hatchedAt = Number(dappmon.hatchedAt?.timestamp || 0);
  const age = formatDistanceStrict(new Date(hatchedAt * 1000), new Date(), {
    addSuffix: false,
  });

  const profileStatsProps = {
    attack: dappmon.attack || 0,
    defense: dappmon.defense || 0,
    tech: dappmon.tech || 0,
    techDefense: dappmon.techDefense || 0,
    speed: dappmon.speed || 0,
    type: TYPE_NAMES[dappmon.dappmonType] || "Unknown",
    stage: STAGE_NAMES[Number(dappmon.stage?.value)] || "Unknown",
    weight: `${dappmon.weight?.toString() || 0}kg`,
    basePower: `${dappmon.basePower || 1}`,
    version: "1.0",
    age: age,
    hatchDate: new Date(hatchedAt * 1000).toISOString().split("T")[0],
    walletAddress: dappmon.owner || "Unknown",
    evoTimer: formatDistanceStrict(
      new Date(),
      new Date(
        (Number(dappmon?.evolvedAt?.value || 0) +
          Number(dappmon?.timeUntilEvolve || 0)) *
          1000
      )
    ),
  };

  const isPublicView = !isOwner; // Determine if the view should be public or private

  return (
    <div className="app">
      <div className="app-container">
        <Navigation />
        <WhiteContainer>
          <TextFlex>
            <Text className="lg centered">Your Dappmon</Text>
          </TextFlex>
        </WhiteContainer>
        <GameScreen title="Profile">
          <WhiteContainer>
            <div className="dappmon-profile-wrapper">
              <ProfileHeader
                dappmon={dappmon.dappmon}
                name={DAPPMON_NAMES[Number(dappmon.dappmon)]}
                hp={dappmon.hp || 0}
              />
              <ProfileStats {...profileStatsProps} />
            </div>
          </WhiteContainer>
        </GameScreen>
        <AlertContainer message={alertMessage} />
        <WhiteContainer>
          <ProfileActionButtons
            dappmonId={dappmonId}
            dappmonName={DAPPMON_NAMES[Number(dappmon.dappmon)]}
            setAlertMessage={setAlertMessage}
            isPublicView={isPublicView}
          />
        </WhiteContainer>
      </div>
    </div>
  );
};

export default Profile;
