import { FC, useEffect, useState } from "react";
import Text from "./ui/Text";
import GameScreen from "./GameScreen";
import WhiteContainer from "./ui/WhiteContainer";
import { Link } from "react-router-dom";
import TextFlex from "./ui/TextFlex";

const WelcomeScreen: FC = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const totalImages = 59;
  const cycleInterval = 500; // 500 ms

  useEffect(() => {
    const imageCycler = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % (totalImages + 1));
    }, cycleInterval);

    return () => clearInterval(imageCycler);
  }, []);

  const getImageSrc = () => {
    if (imageIndex === 0) {
      return "/images/dappmon-mark-black.svg";
    }
    return `/images/dappmon/id/${imageIndex}/${imageIndex}-idle-0.svg`;
  };

  return (
    <GameScreen title="Welcome">
      <WhiteContainer>
      <Text className="mx-auto text-5xl bold text-blue-600 italic text-center">Dappmon</Text>
      <Text className="text-xl centered">A peer-to-peer electronic pet.</Text>
      </WhiteContainer>
      <div className="flex flex-col items-center justify-center w-full h-full space-y-2">
        <div className="flex mb-0 max-w-20 max-h-20">
          <img
            src={getImageSrc()}
            alt="Dappmon"
            width={150}
            height={20}
            className="svg"
          />
        </div>
       
      </div>
      <WhiteContainer className="mx-auto md:w-1/2">
      <Text className="text-3xl centered opacity-50">0.0066 ETH to play.</Text>
      </WhiteContainer>
      <Text className="text-xl centered opacity-50">Generation 1</Text>
        <TextFlex className="w-full pr-4 pl-4 opacity-50">
        <Text className="text-sm sm:text-center">Copyright 2024.</Text>
        <TextFlex>
        <Link to="/terms">
        <Text className="mx-auto text-sm sm:text-center">Terms & Conditions</Text>
        </Link>
        <Text>/</Text>
        <Link  to="/policy">
          <Text className="mx-auto text-sm sm:text-center">Privacy Policy</Text>
        </Link>
        </TextFlex>
        </TextFlex>
    </GameScreen>
  );
};

export default WelcomeScreen;
