import { FC } from "react";
import CareLeft from "./CareLeft";
import CareCenter from "./CareCenter";
import CareRight from "./CareRight";
import { DappmonProps } from "@/types";

const MainGameComponent: FC<DappmonProps> = (dappmonData) => {
  return (
    <div className="flex flex-row w-full h-full">
      <div className="flex-1 flex items-end justify-center">
        <CareLeft selectedItem={dappmonData.selectedItem} />
      </div>
      <div className="flex-3 flex items-center justify-center h-full">
        <CareCenter {...dappmonData} />
      </div>
      <div className="flex-1 flex items-end justify-center">
        <CareRight poop={dappmonData.poop} />
      </div>
    </div>
  );
};

export default MainGameComponent;