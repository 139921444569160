import { usePrivy } from "@privy-io/react-auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useAuthenticated = (navigateTo?: string) => {
  const { ready, authenticated } = usePrivy();
  const navigate = useNavigate();

  useEffect(() => {
    if (ready && !authenticated) {
      navigate(navigateTo || "/");
    }
  }, [ready, authenticated, navigate, navigateTo]);
};
