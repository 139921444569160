import React, { FC, useMemo } from "react";
import Text from "./ui/Text";
import TextFlex from "./ui/TextFlex";
import WhiteContainer from "./ui/WhiteContainer";
import { useAllDappmons } from "@/hooks/useAllDappmons";
import { Link } from "react-router-dom";


const OpenPackage: FC = () => {
  const { dappmons, isLoading } = useAllDappmons();

  const totalMinted = useMemo(() => {
    if (!dappmons) return 0;
    return dappmons.length;
  }, [dappmons]);

  return (
    <>
        <WhiteContainer>
        <TextFlex className="stat">
          <Text className="lg bold">Dappmon Minted:</Text>
          <Text className="xl bold">
            {isLoading ? "-" : totalMinted.toString()}
          </Text>
        </TextFlex>
      </WhiteContainer>
      <WhiteContainer className="">
        <div className="flex flex-wrap justify-center gap-4 m-2">
       <img src="/images/care-package-nft/images/1.png" className="max-w-64 justify-center item-center rounded-2xl shadow-xl"/> 
       <div className="flex flex-col justify-center items-center">
       <Text className="xl centered bold pb-6">
        A Care Package contains:
       </Text>
       <Text className="lg centered">
       20x Food
       </Text>
       <Text className="lg centered">
       5x Protein
       </Text>
       <Text className="lg centered">
       5x Medicine
       </Text>
       <Text className="lg centered pb-6">
       5x Bandages
       </Text>
       <Text className="lg centered pb-6">
       ...and your new best friend onchain.
       </Text>
       <Text className="lg centered pb-6">
       1x Starter Egg
       </Text>
       </div>
        </div>
   
        <TextFlex className="mx-auto px-4 opacity-50 med:w-1/2 sm:w-1/1">
          <Link to="/terms">
            <Text className="text-sm centered">Terms & Conditions</Text>
          </Link>
          <Link to="/policy">
            <Text className="text-sm centered">Privacy Policy</Text>
          </Link>
        </TextFlex>
      </WhiteContainer>
    </>
  );
};

export default OpenPackage;
