import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format, formatDistanceStrict } from "date-fns";
import { useTimer } from "react-timer-hook";
import { toast } from "sonner";
import Navigation from "../components/Navigation";
import Text from "@/components/ui/Text";
import TextFlex from "@/components/ui/TextFlex";
import GameScreen from "../components/GameScreen";
import MainGameComponent from "../components/MainGameComponent";
import WhiteContainer from "@/components/ui/WhiteContainer";
import StatusWrapper from "../components/StatusWrapper";
import AlertContainer from "../components/AlertContainer";
import { CareActionButtons } from "../components/CareActionButtons";
import DeadModal from "../components/DeadModal";
import { useMUD } from "@/MUDContext";
import { useDappmon } from "@/hooks/useDappmon";
import { useInventory } from "@/hooks/useInventory";
import { useSoundContext } from "@/SoundContext";
import { handleError } from "@/lib/error";
import { trimHash } from "@/lib/utils";
import { DAPPMON_NAMES, STAGE_NAMES, TYPE_NAMES } from "@/constants";

const ANIMATION_DURATION = 3000;
const MAX_OVERFEED_VALUE = 16;

export const Care: FC = () => {
  const mud = useMUD();
  const { dappmonId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<"food" | "protein" | "bandage" | "medicine" | null>(null);
  const [animationState, setAnimationState] = useState<"happy" | "sad" | "idle" | "dead">("idle");
  const [alertMessage, setAlertMessage] = useState<string>("Select an action");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { dappmon, isOwner } = useDappmon(dappmonId ? BigInt(dappmonId) : undefined);
  const { inventory } = useInventory();
  const { playHappy, playSad } = useSoundContext();

  const dappmonName = dappmon?.dappmon ? DAPPMON_NAMES[dappmon.dappmon] : "Dappmon";
  const weight = Number(dappmon?.weight || 1);
  const isSick = dappmon?.isSick || false;
  const isTired = dappmon?.isTired || false;
  const isInjured = dappmon?.isInjured || false;
  const isSleeping = dappmon?.isSleeping || false;
  const isDead = dappmon?.hasDied || false;
  const hatchedAt = Number(dappmon?.hatchedAt?.timestamp || 0);
  const age = formatDistanceStrict(new Date(hatchedAt * 1000), new Date(), { addSuffix: false });

  const [expiryTimestamp, setExpiryTimestamp] = useState<Date>(new Date());

  const { seconds, minutes, hours, isRunning, restart } = useTimer({
    expiryTimestamp: expiryTimestamp,
    autoStart: false,
    onExpire: () => console.log('Timer expired'),
  });
  
  useEffect(() => {
    if (dappmon?.fedAt) {
      const newExpiryTimestamp = new Date((Number(dappmon.fedAt.timestamp) + 45 * 60) * 1000);
      setExpiryTimestamp(newExpiryTimestamp);
      restart(newExpiryTimestamp);
    }
  }, [dappmon, restart]);

  const isPublicView = !isOwner;

  const updateAnimationState = useCallback(
    (newState: "happy" | "sad" | "idle" | "dead", duration: number = ANIMATION_DURATION) => {
      setAnimationState(newState);
  
      if (!isDead) {
        setTimeout(() => {
          setAnimationState("idle");
          setSelectedItem(null);
        }, duration);
      }
    },
    [isDead]
  );
  
  useEffect(() => {
    if (!isRunning && hatchedAt > 0) {
      restart(new Date((Number(dappmon?.fedAt?.timestamp || 0) + 6 * 60 * 60) * 1000));
    }
  }, [dappmon, hatchedAt, isRunning, restart]);

  useEffect(() => {
    if (isDead) {
      setAnimationState("dead");
      setIsModalOpen(true);
    }
  }, [isDead]);

  useEffect(() => {
    if (isSleeping) {
      updateAnimationState("idle", Infinity);
    } else {
      updateAnimationState("idle");
    }
  }, [isSleeping, updateAnimationState]);

  const handleMintAnother = useCallback(() => {
    navigate("/start");
  }, [navigate]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleFood = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !dappmon || isDead) return;
      if (!inventory?.food || inventory.food <= 0) {
        toast.error("No inventory, visit shop.");
        return;
      }
      const overfeedValue = dappmon?.hunger ?? 0;
      if (overfeedValue >= MAX_OVERFEED_VALUE) {
        toast.error(`${dappmonName} is too full to eat more!`);
        return;
      }
      setLoading(true);
      setAlertMessage("Feeding...");
      await mud?.systemCalls.feed(dappmon.dappmonId);
      toastId = toast.success(`${dappmonName} is eating!`);
      playHappy();
      updateAnimationState("happy");
      setSelectedItem("food");
  
      // Increase timer by 90 minutes
      const currentTime = new Date();
      const newExpiryTime = new Date(Math.max(currentTime.getTime(), expiryTimestamp.getTime()) + (90 * 60 * 1000));
      setExpiryTimestamp(newExpiryTime);
      restart(newExpiryTime);
  
    } catch (err) {
      handleError(err, toastId);
      playSad();
      updateAnimationState("sad");
    } finally {
      setLoading(false);
      setAlertMessage("Select an action");
    }
  }, [mud, dappmon, dappmonName, isDead, playHappy, playSad, inventory, updateAnimationState, restart, expiryTimestamp]);

  const handleProtein = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !dappmon || isDead) return;
      if (!inventory?.protein || inventory.protein <= 0) {
        toast.error("No inventory, visit shop.");
        return;
      }
      const overfeedValue = dappmon?.hunger ?? 0;
      if (overfeedValue >= MAX_OVERFEED_VALUE) {
        toast.error(`${dappmonName} is too full for more protein!`);
        return;
      }
      setLoading(true);
      setAlertMessage("Giving protein...");
      await mud?.systemCalls.protein(dappmon.dappmonId);
      toastId = toast.success(`${dappmonName} feels stronger!`);
      playHappy();
      updateAnimationState("happy");
      setSelectedItem("protein");
    } catch (err) {
      handleError(err, toastId);
      playSad();
      updateAnimationState("sad");
    } finally {
      setLoading(false);
      setAlertMessage("Select an action");
    }
  }, [mud, dappmon, dappmonName, isDead, playHappy, playSad, inventory, updateAnimationState]);

  const handleBandage = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !dappmon || isDead) return;
      if (!inventory?.bandage || inventory.bandage <= 0) {
        toast.error("No inventory, visit shop.");
        return;
      }
      if (!isInjured) {
        toast.success(`${dappmonName} is not injured!`);
        updateAnimationState("happy");
        return;
      }
      setLoading(true);
      setAlertMessage("Applying bandage...");
      await mud?.systemCalls.bandage(dappmon.dappmonId);
      toastId = toast.success(`${dappmonName} is bandaged!`);
      playHappy();
      updateAnimationState("happy");
      setSelectedItem("bandage");
    } catch (err) {
      handleError(err, toastId);
      playSad();
      updateAnimationState("sad");
    } finally {
      setLoading(false);
      setAlertMessage("Select an action");
    }
  }, [mud, dappmon, dappmonName, isInjured, isDead, playHappy, playSad, inventory, updateAnimationState]);

  const handleMedicine = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !dappmon || isDead) return;
      if (!inventory?.medicine || inventory.medicine <= 0) {
        toast.error("No inventory, visit shop.");
        return;
      }
      if (!isSick) {
        toast.success(`${dappmonName} is healthy!`);
        updateAnimationState("happy");
        return;
      }
      setLoading(true);
      setAlertMessage("Giving medicine...");
      await mud?.systemCalls.cure(dappmon.dappmonId);
      toastId = toast.success(`${dappmonName} took medicine!`);
      playHappy();
      updateAnimationState("happy");
      setSelectedItem("medicine");
    } catch (err) {
      handleError(err, toastId);
      playSad();
      updateAnimationState("sad");
    } finally {
      setLoading(false);
      setAlertMessage("Select an action");
    }
  }, [mud, dappmon, dappmonName, isSick, isDead, playHappy, playSad, inventory, updateAnimationState]);

  const handleTrain = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !dappmon || isDead) return;
      if (weight <= Number(1)) {
        toast.error(`${dappmonName} doesn't weigh enough.`);
        return;
      }
      setLoading(true);
      setAlertMessage("Training...");
      await mud?.systemCalls.train(dappmon.dappmonId);
      toastId = toast.success(`${dappmonName} trained!`);
      playHappy();
      updateAnimationState("happy");
    } catch (err) {
      handleError(err, toastId);
      playSad();
      updateAnimationState("sad");
    } finally {
      setLoading(false);
      setAlertMessage("Select an action");
    }
  }, [mud, dappmon, dappmonName, isDead, playHappy, playSad, weight, updateAnimationState]);

  const handleEvolve = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !dappmon || isDead) return;
      setLoading(true);
      setAlertMessage("Evolving...");
      await mud?.systemCalls.evolve(dappmon.dappmonId);
      toastId = toast.success(`${dappmonName} has evolved!`);
      playHappy();
      updateAnimationState("happy");
    } catch (err) {
      handleError(err, toastId);
      playSad();
      updateAnimationState("sad");
    } finally {
      setLoading(false);
      setAlertMessage("Select an action");
    }
  }, [mud, dappmon, dappmonName, isDead, playHappy, playSad, updateAnimationState]);

  const handleSleep = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !dappmon || isDead) return;
      setLoading(true);
      setAlertMessage("Sleeping...");
      await mud?.systemCalls.sleep(dappmon.dappmonId);
      toastId = toast.success(`${dappmonName} went to sleep.`);
      playHappy();
      updateAnimationState("idle", Infinity);
    } catch (err) {
      handleError(err, toastId);
      playSad();
      updateAnimationState("sad");
    } finally {
      setLoading(false);
      setAlertMessage("Select an action");
    }
  }, [mud, dappmon, dappmonName, isDead, playHappy, playSad, updateAnimationState]);

  const handleClean = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !dappmon || isDead) return;
      setLoading(true);
      setAlertMessage("Cleaning...");
      await mud?.systemCalls.clean(dappmon.dappmonId);
      toastId = toast.success(`${dappmonName}'s mess cleaned!`);
      playHappy();
      updateAnimationState("happy");
    } catch (err) {
      handleError(err, toastId);
      playSad();
      updateAnimationState("sad");
    } finally {
      setLoading(false);
      setAlertMessage("Select an action");
    }
  }, [mud, dappmon, dappmonName, isDead, playHappy, playSad, updateAnimationState]);

  return (
    <>
      <div className="app">
        <div className="app-container">
          <Navigation />
          <WhiteContainer>
            <TextFlex>
              <Text className="lg centered">Main</Text>
            </TextFlex>
          </WhiteContainer>
          <GameScreen title="Main">
            {isModalOpen && (
              <DeadModal onMintAnother={handleMintAnother} onClose={handleCloseModal} />
            )}
            <MainGameComponent
              id={dappmon?.dappmon}
              attack={dappmon?.attack || 0}
              defense={dappmon?.defense || 0}
              tech={dappmon?.tech || 0}
              techDefense={dappmon?.techDefense || 0}
              speed={dappmon?.speed || 0}
              type={dappmon?.dappmonType ? TYPE_NAMES[dappmon.dappmonType] : "Unknown"}
              stage={dappmon?.stage ? STAGE_NAMES[Number(dappmon.stage?.value)] : ""}
              weight={`${dappmon?.weight?.toString()}kg`}
              version={"1.0"}
              age={age}
              hatchDate={format(new Date(hatchedAt * 1000), "yyyy-MM-dd")}
              walletAddress={trimHash(dappmon?.owner)}
              evoTimer={"00:30:00"}
              elo={1500}
              wins={10}
              isWalking={!isDead && !isSleeping}
              isSleeping={!isDead && isSleeping}
              isSick={isSick}
              isTired={isTired}
              isDead={isDead}
              state={animationState}
              poop={Number(dappmon?.poopCount || 0)}
              selectedItem={selectedItem}
            />
         <StatusWrapper
              id={dappmonId ? Number(dappmonId) : 0}
              name={dappmon?.dappmon ? DAPPMON_NAMES[dappmon.dappmon] : "Name"}
              stage={dappmon?.stage ? STAGE_NAMES[Number(dappmon.stage?.value)] : ""}
              weight={dappmon?.weight ? `${dappmon.weight.toString()}kg` : `0kg`}
              sick={isSick}
              hurt={isInjured}
              tired={isTired}
              hunger={dappmon?.hunger}
              effort={dappmon?.effort ?? BigInt(0)}
              feedTimer={`${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`}
              canEvolve={Boolean(dappmon?.canEvolve)}
              giftEggsAvailable={dappmon?.giftEggsAvailable}
              onEvolve={handleEvolve}
              canSleep={isTired}
              onSleep={handleSleep}
              canClean={Number(dappmon?.poopCount || 0) > 0}
              onClean={handleClean}
            />
          </GameScreen>
          <AlertContainer message={alertMessage} />
          <WhiteContainer>
            <CareActionButtons
              loading={loading}
              dappmonId={dappmonId ? BigInt(dappmonId) : BigInt(0)}
              foodCount={inventory?.food}
              bandageCount={inventory?.bandage}
              medicineCount={inventory?.medicine}
              proteinCount={inventory?.protein}
              onFood={handleFood}
              onProtein={handleProtein}
              onBandage={handleBandage}
              onMedicine={handleMedicine}
              onTrain={handleTrain}
              setAlertMessage={setAlertMessage}
              isPublicView={isPublicView}
            />
          </WhiteContainer>
        </div>
      </div>
    </>
  );
};

export default Care;