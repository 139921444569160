import { FC, useState, useCallback } from "react";
import Navigation from "../components/Navigation";
import StarterPackageContent from "../components/StarterPackageContent";
import Button from "../components/ui/Button";
import Text from "../components/ui/Text";
import TextFlex from "../components/ui/TextFlex";
import ButtonGrid from "../components/ButtonGrid";
import { useMUD } from "@/MUDContext";
import { useShop } from "@/hooks/useShop";
import { toast } from "sonner";
import { handleError } from "@/lib/error";
import { useNavigate } from "react-router-dom";
import WhiteContainer from "@/components/ui/WhiteContainer";
import AlertContainer from "@/components/AlertContainer";
import GameScreen from "@/components/GameScreen";
import { useAuthenticated } from "@/hooks/useAuthenticated";

export const Start: FC = () => {
  useAuthenticated();
  const mud = useMUD();
  const { shop } = useShop();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>(
    "Buy a starter package."
  );

  const handleMint = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !shop) return;

      setLoading(true);
      await shop.purchasePackage();
      toastId = toast.success("Success!");
      navigate(`/open`);
    } catch (err) {
      handleError(err, toastId);
    } finally {
      setLoading(false);
    }
  }, [mud, navigate, shop]);

  return (
    <>
    <div className="app">
      <div className="app-container">
        <Navigation />
        <WhiteContainer>
          <TextFlex>
            <Text className="lg centered">Dappmon Starter Package</Text>
          </TextFlex>
        </WhiteContainer>
        <GameScreen title="Starter Package">
         
          <StarterPackageContent packagePrice={shop?.packagePrice} />
        
        </GameScreen>
        <AlertContainer message={alertMessage} />
        <WhiteContainer>
          <ButtonGrid className="mint">
            <Button
              disabled={loading}
              className="mint w-button"
              onClick={handleMint}
              onMouseOver={() => setAlertMessage("Purchase?")}
              onMouseOut={() => setAlertMessage("Buy a starter package.")}
            >
              {loading ? "Loading..." : "Mint"}
            </Button>
          </ButtonGrid>
        </WhiteContainer>
      </div>
      </div>
    </>
  );
};

export default Start;
