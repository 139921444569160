import { FC } from "react";
import { DappmonProps } from "@/types";
import DappmonAnimation from "./DappmonAnimation";

const CareCenter: FC<DappmonProps> = (props) => {
  return (
   
      <DappmonAnimation
        id={props.id}
        state={props.state}
        isWalking={props.isWalking}
        isSleeping={props.isSleeping}
        isDead={props.isDead}
      />

  );
};

export default CareCenter;
