import React from "react";
import { useNavigate } from "react-router-dom";
import TextFlex from "./ui/TextFlex";
import Button from "./ui/Button";
import { useDappmons } from "@/hooks/useDappmons";
import { usePackages } from "@/hooks/usePackages";

const Navigation: React.FC = () => {
  const { dappmons } = useDappmons();
  const { packages } = usePackages();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if ((dappmons && dappmons.length > 0) || (packages && packages.length > 0)) {
      navigate("/dappmons");
    } else {
      navigate("/start");
    }
  };

  const handleGoBackOrNavigate = (path: string) => {
    if (window.location.pathname === path) {
      navigate(-1);
    } else {
      navigate(path);
    }
  };

  const hasDappmon = (dappmons && dappmons.length > 0) || (packages && packages.length > 0);

  return (
    <div className="navigation-container">
      <TextFlex className="navigation">
        <div className="logo-container" onClick={handleLogoClick}>
          <img
            src="/images/dappmon-mark-black.svg"
            loading="lazy"
            alt="Dappmon Mark"
            className="svg logo"
            style={{ cursor: "pointer" }}
          />
        </div>
        <TextFlex>
          {hasDappmon ? (
            <>
              <Button className="menu" onClick={() => handleGoBackOrNavigate('/dappmons')}>
                <img
                  src="/images/ui/ui-inventory-0.svg"
                  loading="lazy"
                  alt="Dappmons"
                  className="svg lg"
                />
              </Button>
              <Button className="menu" onClick={() => handleGoBackOrNavigate('/shop')}>
                <img
                  src="/images/ui/ui-treasure-0.svg"
                  loading="lazy"
                  alt="Shop"
                  className="svg lg"
                />
              </Button>
              <Button className="menu" onClick={() => handleGoBackOrNavigate('/leaderboard')}>
                <img
                  src="/images/ui/ui-leaderboard-0.svg"
                  loading="lazy"
                  alt="Leaderboard"
                  className="svg lg"
                />
              </Button>
            </>
          ) : null}
          <Button className="menu" onClick={() => handleGoBackOrNavigate('/settings')}>
            <img
              src="/images/ui/ui-settings-0.svg"
              loading="lazy"
              alt="Settings"
              className="svg lg"
            />
          </Button>
        </TextFlex>
      </TextFlex>
    </div>
  );
};

export default Navigation;
