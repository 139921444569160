import { useMUD, MUDContextResult } from "@/MUDContext";
import { getComponentValue } from "@latticexyz/recs";
import { encodeEntity } from "@latticexyz/store-sync/recs";
import { useQuery } from "@tanstack/react-query";
import { useAccount } from "wagmi";

const fetchDappmonStats = async (mud: MUDContextResult, dappmonId: bigint) => {
  const [hp, attack, defense, tech, techDefense, speed, basePower] =
    await Promise.all([
      mud.systemCalls?.getHp(dappmonId),
      mud.systemCalls?.getAttack(dappmonId),
      mud.systemCalls?.getDefense(dappmonId),
      mud.systemCalls?.getTech(dappmonId),
      mud.systemCalls?.getTechDefense(dappmonId),
      mud.systemCalls?.getSpeed(dappmonId),
      mud.systemCalls?.getBasePower(dappmonId),
    ]);

  return {
    hp,
    attack,
    defense,
    tech,
    techDefense,
    speed,
    basePower,
  };
};

export const fetchDappmon = async (
  mud: MUDContextResult,
  dappmonId: bigint
) => {
  const entity = encodeEntity(
    {
      dappmonId: "uint256",
    },
    {
      dappmonId,
    }
  );

  const stage = getComponentValue(mud.components.Stage, entity);
  const hatchedAt = getComponentValue(mud.components.HatchedAt, entity);
  const evolvedAt = getComponentValue(mud.components.EvolvedAt, entity);
  const fedAt = getComponentValue(mud.components.FedAt, entity);

  const stats = await fetchDappmonStats(mud, dappmonId);

  return {
    dappmonId,
    stage,
    hatchedAt,
    evolvedAt,
    fedAt,
    dappmon: await mud.systemCalls?.getDappmon(dappmonId),
    owner: await mud.systemCalls?.getDappmonOwnerOf(dappmonId),
    timeUntilEvolve: await mud.systemCalls?.timeUntilEvolve(dappmonId),
    canEvolve: await mud.systemCalls?.canEvolve(dappmonId),
    hunger: await mud.systemCalls?.getHunger(dappmonId),
    strength: BigInt(0),
    poopCount: await mud.systemCalls?.getPoopCount(dappmonId),
    weight: await mud.systemCalls?.getWeight(dappmonId),
    effort: await mud.systemCalls?.getEffort(dappmonId),
    hasDied: await mud.systemCalls?.hasDied(dappmonId),
    isSleeping: await mud.systemCalls?.isSleeping(dappmonId),
    isTired: await mud.systemCalls?.isTired(dappmonId),
    isSick: await mud.systemCalls?.isSick(dappmonId),
    isInjured: await mud.systemCalls?.isInjured(dappmonId),
    dappmonType: await mud.systemCalls?.getType(dappmonId),
    giftEggsAvailable: await mud.systemCalls?.getGiftEggsAvailable(dappmonId),
    ...stats,
  };
};

export const useDappmon = (dappmonId?: bigint) => {
  const mud = useMUD();
  const { address } = useAccount();
  const {
    data: dappmon,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery({
    queryKey: ["dappmon", dappmonId?.toString()],
    queryFn: async () => {
      if (!mud?.systemCalls || !dappmonId) return null;

      return fetchDappmon(mud, dappmonId);
    },
    enabled: !!mud?.systemCalls && !!dappmonId,
    refetchOnWindowFocus: "always",
    refetchIntervalInBackground: true,
    refetchInterval: 1000,
  });

  return {
    dappmon,
    isOwner: address
      ? address.toLowerCase() === dappmon?.owner?.toLowerCase()
      : false,
    isLoading,
    isFetching,
    error,
    refetch,
  };
};
