import React from "react";

interface ButtonGridProps {
  children: React.ReactNode;
  className?: string;
}

const ButtonGrid: React.FC<ButtonGridProps> = ({ children, className }) => {
  return <div className={`button-grid ${className}`}>{children}</div>;
};

export default ButtonGrid;
