import { useMUD } from "@/MUDContext";
import { ItemType } from "@/types";
import { useQuery } from "@tanstack/react-query";

export const useInventory = () => {
  const mud = useMUD();
  const {
    data: inventory,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["inventory"],
    queryFn: async () => {
      if (!mud?.systemCalls) return null;

      return {
        dappmons: await mud.systemCalls.getDappmonBalance(),
        packages: await mud.systemCalls.getPackageBalance(),
        food: await mud.systemCalls.getItemsBalance(BigInt(ItemType.Food)),
        bandage: await mud.systemCalls.getItemsBalance(BigInt(ItemType.Bandage)),
        protein: await mud.systemCalls.getItemsBalance(BigInt(ItemType.Protein)),
        medicine: await mud.systemCalls.getItemsBalance(BigInt(ItemType.Medicine)),
      };
    },
    enabled: !!mud?.systemCalls,
    refetchOnWindowFocus: "always",
  });

  return {
    inventory,
    isLoading,
    isFetching,
    refetch,
  };
};
