export const DAPPMONS = [
  "Starter Egg",
  "Weimon",
  "Kweimon",
  "Mweimon",
  "Babbagemon",
  "Gweimon",
  "DarkGweimon",
  "Finnemon",
  "DarkFinnemon",
  "Ethemon",
  "SkullEthemon",
  "Nodemon",
  "DarkNodemon",
  "AdaEgg",
  "Adamon",
  "Hashmon",
  "Szabomon",
  "Stakemon",
  "Satomon",
  "Bitmon",
  "HairyBitmon",
  "Cardomon",
  "Basemon",
  "Solamon",
  "Beeplemon",
  "DefiEgg",
  "Defimon",
  "Tokemon",
  "Litemon",
  "Ripplemon",
  "Linkmon♀",
  "Linkmon♂",
  "Minemon",
  "Boreapemon",
  "Apparimon",
  "DarkApparimon",
  "Whalemon",
  "Niftimon",
  "HodlEgg",
  "Hodlmon",
  "Proofmon",
  "Oracamon",
  "Ledgemon",
  "Gigamon",
  "DumbGigamon",
  "Harambemon",
  "Chaimon",
  "Nonceamon",
  "Quorumon",
  "Dexmon",
  "Decntramon",
  "TruffEgg",
  "Truffmon",
  "Merklemon",
  "Gatomon",
  "Validamon",
  "DarkValidamon",
  "Gasimon",
  "Delegamon",
];

export const STAGES = [
  "None",
  "Egg",
  "Baby",
  "Child",
  "Training",
  "Battle",
  "Champ",
];

export const TYPES = ["Null", "Decrypt", "Encrypt", "Cipher"];

export const DAPPMON_NAMES = DAPPMONS.reduce(
  (acc, dappmon, i) => ({ ...acc, [i + 1]: dappmon }),
  {} as Record<number, string>
);

export const STAGE_NAMES = STAGES.reduce(
  (acc, stage, i) => ({ ...acc, [i]: stage }),
  {} as Record<number, string>
);

export const TYPE_NAMES = TYPES.reduce(
  (acc, dType, i) => ({ ...acc, [i]: dType }),
  {} as Record<number, string>
);

export const SKILL_NAMES = [
  "Block", "Bit Flip", "Buffer Bonk", "Phishing Strike", "Quantum Tunnel", "Ghost Protocol", "Byte Blast",
  "Encryption Wave", "Cryptic Missile", "Syntax Slam", "Rootkit Rampage", "Packet Storm", "Opensource Shot",
  "Distributed Dunk", "Logic Bomb", "Malicious Code", "Keylog Strike", "Decryption Blast", "Firewall Breach",
  "Brute Force", "Payload Drive", "Protocol Heal", "Release Update", "Secure Channel", "Firmware Update I",
  "Firmware Update II", "Firmware Update III", "Cipher Shield I", "Cipher Shield II", "Cipher Shield III",
  "Fortify Code I", "Fortify Code II", "Fortify Code III", "Data Cloak I", "Data Cloak II", "Data Cloak III",
  "Overclock I", "Overclock II", "Overclock III", "Recursive Hack I", "Recursive Hack II", "Recursive Hack III",
  "Vulnerable Scan I", "Vulnerable Scan II", "Vulnerable Scan III", "Data Leak I", "Data Leak II", "Data Leak III",
  "Exploit Weakness I", "Exploit Weakness II", "Exploit Weakness III", "Backdoor Entry I", "Backdoor Entry II",
  "Backdoor Entry III"
];

export const SKILL_TYPE_NAMES = ["Null", "Decrypt", "Encrypt", "Cipher"];

export const SKILL_ATTRIBUTE_NAMES = ["None", "Attack", "Defense", "Tech", "TechDefense", "Speed"];

export const SKILL_CATEGORIES = ["Block", "Attack", "Tech", "Heal", "Buff", "Debuff"];
