import { useMUD } from "@/MUDContext";
import { useQuery } from "@tanstack/react-query";

export const usePackages = () => {
  const mud = useMUD();
  const {
    data: packages,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["packages"],
    queryFn: async () => {
      if (!mud?.systemCalls) return [];

      return mud.systemCalls.getPackageIds();
    },
    enabled: !!mud?.systemCalls,
    refetchOnWindowFocus: "always",
  });

  return {
    packages,
    isLoading,
    isFetching,
    refetch,
  };
};
