import React from "react";

interface HeartsProps {
  value: number;
}

const Hearts: React.FC<HeartsProps> = ({ value }) => {
  const getHeartImage = (index: number): string => {
    const fullHearts = Math.floor(value / 2);
    const halfHeart = value % 2;

    if (index < fullHearts) {
      return "/images/ui/ui-heart-2.svg";
    } else if (index === fullHearts && halfHeart === 1) {
      return "/images/ui/ui-heart-1.svg";
    } else {
      return "/images/ui/ui-heart-0.svg";
    }
  };

  return (
    <div className="icon-grid">
      {Array.from({ length: 4 }).map((_, index) => (
        <img
          key={index}
          src={getHeartImage(index)}
          alt="Heart"
          width={36}
          height={36}
          className="svg"
        />
      ))}
    </div>
  );
};

export default Hearts;
