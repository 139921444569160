import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAllDappmons } from "@/hooks/useAllDappmons";
import Navigation from "../components/Navigation";
import AlertContainer from "../components/AlertContainer";
import Button from "@/components/ui/Button";
import ButtonGrid from "../components/ButtonGrid";
import Text from "../components/ui/Text";
import TextFlex from "@/components/ui/TextFlex";
import WhiteContainer from "@/components/ui/WhiteContainer";
import LeaderboardDappmon from "../components/LeaderboardDappmon";
import { STAGE_NAMES, DAPPMON_NAMES } from "@/constants";
import { toast } from "sonner";

export const Leaderboard: React.FC = () => {
  const navigate = useNavigate();
  const { dappmons, error } = useAllDappmons();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredDappmons, setFilteredDappmons] = useState<any[]>([]);
  const [alertMessage, setAlertMessage] = useState<string>("Enter a Dappmon ID.");

  useEffect(() => {
    if (dappmons) {
      const aliveDappmons = dappmons.filter((dappmon) => !dappmon.hasDied);
      const sortedDappmons = aliveDappmons.sort((a, b) => {
        const effortA = Number(a.effort || 0);
        const effortB = Number(b.effort || 0);
        return effortB - effortA; // Sort in descending order by effort
      });

      setFilteredDappmons(sortedDappmons);
    }
  }, [dappmons]);

  useEffect(() => {
    if (searchTerm) {
      const results = filteredDappmons.filter((dappmon) =>
        dappmon.dappmonId.toString().includes(searchTerm)
      );
      setFilteredDappmons(results);
    }
  }, [searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (error) {
      toast.error("Error loading Dappmons");
    }
  }, [error]);

  return (
    <div className="app">
      <div className="app-container">
        <Navigation />
        <WhiteContainer>
          <TextFlex>
            <Text className="lg centered">Leaderboard</Text>
          </TextFlex>
        </WhiteContainer>
        <div className="game-screen-container">
          <div className="game-screen-wrapper">
            <div className="tabs relative">
              <div className="tabs-menu white-container sticky top-0 z-20">
                <div className="search-bar">
                  <input
                    className="input text w-input w-full sticky top-0"
                    maxLength={256}
                    placeholder="Enter Dappmon ID to search"
                    type="number"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              <div className="tabs-content">
                <div className="tab-content">
                  <div>
                    {filteredDappmons.length > 0 ? (
                      filteredDappmons.map((dappmon, index) => {
                        const stage = STAGE_NAMES[Number(dappmon.stage?.value)];
                        if (
                          !stage ||
                          stage === "Egg" ||
                          stage === "None" ||
                          !dappmon.hatchedAt
                        ) {
                          return null;
                        }

                        const effort = Number(dappmon.effort || 0);

                        return (
                          <LeaderboardDappmon
                            key={dappmon.dappmonId.toString()}
                            rank={index + 1}
                            imageSrc={`/images/dappmon/id/${dappmon.dappmon}/${dappmon.dappmon}-idle-0.svg`}
                            stage={stage}
                            effort={effort}
                            dappmonId={dappmon.dappmonId}
                            name={
                              dappmon.dappmon
                                ? DAPPMON_NAMES[dappmon.dappmon]
                                : ""
                            }
                          />
                        );
                      })
                    ) : (
                      <Text className="xl p-4">No Dappmons found.</Text>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AlertContainer message={alertMessage} />
        <WhiteContainer>
          <ButtonGrid className="back">
            <Button
              className="back dark"
              onClick={() => navigate(-1)}
              onMouseOver={() =>
                setAlertMessage("Go back to the previous screen")
              }
              onMouseOut={() => setAlertMessage("Enter a Dappmon ID.")}
            >
              <img
                src="/images/ui/ui-arrow-down.svg"
                alt="Back"
                width={24}
                height={24}
                className="svg white lg"
              />
              <Text>Back</Text>
            </Button>
          </ButtonGrid>
        </WhiteContainer>
      </div>
    </div>
  );
};

export default Leaderboard;
