import { defineWorld } from "@latticexyz/world";

export default defineWorld({
  enums: {
    StageType: ["None", "Egg", "Baby", "Child", "Training", "Battle", "Champion"],
    ItemType: ["None", "Food", "Bandage", "Protein", "Medicine"],
    DappmonType: ["Null", "Decrypt", "Encrypt", "Cipher"],
    SkillType: ["Null", "Decrypt", "Encrypt", "Cipher"],
    SkillCategory: ["Attack", "Tech", "Heal", "Buff", "Debuff", "Block"],
    SkillAttributes: ["None", "Attack", "Tech", "Defense", "TechDefense", "Speed"],
    OperatorPermissions: ["None", "Operator", "SuperOperator"],
  },
  tables: {
    Config: {
      codegen: {
        dataStruct: false,
      },
      schema: {
        dappmonsAddress: "address",
        packagesAddress: "address",
        itemsAddress: "address",
        skillsAddress: "address",
        lootAddress: "address",
        treasuryAddress: "address",
      },
      key: [],
    },
    Operator: {
      schema: {
        operator: "address",
        permissions: "OperatorPermissions",
      },
      key: ["operator"],
    },
    HatchedAt: {
      schema: {
        dappmonId: "uint256",
        timestamp: "uint256",
      },
      key: ["dappmonId"],
    },
    HatchedBy: {
      schema: {
        dappmonId: "uint256",
        player: "address",
      },
      key: ["dappmonId"],
    },
    Stage: {
      schema: {
        dappmonId: "uint256",
        value: "StageType",
      },
      key: ["dappmonId"],
    },
    EvolvedAt: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    FedAt: {
      schema: {
        dappmonId: "uint256",
        timestamp: "uint256",
        hunger: "uint256",
      },
      key: ["dappmonId"],
    },
    Overfed: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    CleanedAt: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    SickAt: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    SleptAt: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    BornAt: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    InjuredAt: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    FeedingMistakes: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    SickMistakes: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    Weight: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    Effort: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    Stamina: {
      schema: {
        dappmonId: "uint256",
        value: "uint256",
      },
      key: ["dappmonId"],
    },
    SkillSlots: {
      schema: {
        dappmonId: "uint256",
        slot: "uint8",
        skillId: "uint256",
      },
      key: ["dappmonId", "slot"],
    },
    Challenges: {
      schema: {
        dappmonId: "uint256",
        index: "uint256",
        targetId: "uint256",
        timestamp: "uint256",
        accepted: "bool",
        rejected: "bool",
      },
      key: ["dappmonId", "index"],
    },
    Battles: {
      schema: {
        dappmonId: "uint256",
        index: "uint256",
        battleId: "bytes32",
        active: "bool",
        opponentId: "uint256",
      },
      key: ["dappmonId", "index"],
    },
    BattleResults: {
      schema: {
        battleId: "bytes32",
        actions: "uint256",
        winnerId: "uint256",
        timestamp: "uint256",
      },
      key: ["battleId"],
    },
    BattlePlayerResults: {
      schema: {
        battleId: "bytes32",
        dappmonId: "uint256",
        hp: "uint256",
        attack: "uint256",
        def: "uint256",
        tech: "uint256",
        techDef: "uint256",
        speed: "uint256",
        lastAction: "uint256",
      },
      key: ["battleId", "dappmonId"],
    },
    BattleActions: {
      codegen: {
        dataStruct: false,
      },
      schema: {
        battleId: "bytes32",
        actionId: "uint256",
        dappmonId: "uint256",
        skillId: "uint256",
        damage: "uint256",
        heal: "uint256",
        attribute: "SkillAttributes",
        increase: "uint256",
        decrease: "uint256",
        timestamp: "uint256",
      },
      key: ["battleId", "actionId"],
    },
    Records: {
      schema: {
        dappmonId: "uint256",
        challenges: "uint256",
        battles: "uint256",
        wins: "uint256",
        losses: "uint256",
      },
      key: ["dappmonId"],
    },
    Shop: {
      schema: {
        slots: "uint8",
        packagePrice: "uint256",
        active: "bool",
      },
      key: [],
    },
    ShopSlots: {
      schema: {
        slot: "uint8",
        itemId: "uint8",
        quantity: "uint256",
        price: "uint256",
        active: "bool",
      },
      key: ["slot"],
    },
    GiftEgg: {
      schema: {
        dappmonId: "uint256",
        available: "uint256",
        claimed: "uint256",
      },
      key: ["dappmonId"],
    },
  },
});
