import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./ui/Button";

interface LeaderboardDappmonProps {
  rank: number;
  imageSrc: string;
  stage: string;
  effort: number;
  dappmonId: bigint;
  name: string;
}

const LeaderboardDappmon: React.FC<LeaderboardDappmonProps> = ({
  rank,
  imageSrc,
  stage,
  effort,
  dappmonId,
  name,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/dappmons/${dappmonId}`);
  };

  return (
    <div className="px-4 py-2 sm:py-2 border-b border-gray-200 dark:border-gray-700">
      <div className="flex items-center">
        <div className="flex-shrink-0 min-w-16">
          <img
            src={imageSrc}
            alt="Dappmon"
            className="w-16 h-16 svg"
          />
        </div>
        <div className="flex-1 min-w-0 ms-4">
          <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
            {rank}. {name}
          </p>
          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
            Stage: {stage} | Effort: {effort}
          </p>
        </div>
        <div className="inline-flex items-center text-base text-gray-900 dark:text-white">
          <Button className="w-full min-w-36 sm:w-auto mt-2 sm:mt-0" onClick={handleClick}>
            View
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardDappmon;
