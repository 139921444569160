import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { http } from "wagmi";
import { WagmiProvider, createConfig } from "@privy-io/wagmi";
import { Chain } from "wagmi/chains";
import { PrivyProvider } from "./PrivyProvider";
import { SoundContextProvider } from "./SoundContext";
import { MUDProvider } from "./MUDContext";
import { chain } from "./mud/supportedChains";
import { fallback } from "viem";
import { router } from "./routes";
import "./globals.css";

export const config = createConfig({
  chains: [chain as Chain],
  transports: {
    [chain.id]: fallback(
      [
        http((chain as Chain)?.rpcUrls?.default?.http[0], {
          batch: true,
        }),
        http((chain as Chain)?.rpcUrls?.public?.http[0], {
          batch: true,
        }),
      ],
      { rank: true }
    ),
  },
  batch: { multicall: true },
});

const rootElement = document.getElementById("react-root");
if (!rootElement) throw new Error("React root not found");
const root = ReactDOM.createRoot(rootElement);
const queryClient = new QueryClient();

root.render(
  <PrivyProvider>
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={config}>
        <MUDProvider>
          <SoundContextProvider>
            <RouterProvider router={router} />
          </SoundContextProvider>
        </MUDProvider>
      </WagmiProvider>
    </QueryClientProvider>
  </PrivyProvider>
);
