import { createContext, ReactNode, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { setup, SetupResult } from "./mud/setup";
import { useWalletClient } from "wagmi";
import {
  Chain,
  createPublicClient,
  http,
  PublicClient,
  WalletClient,
} from "viem";
import { LoadingScreen } from "./components/LoadingScreen";
import { chain } from "@/mud/supportedChains";
import { fallback } from "viem";
import { MUDStateResult, setupState } from "./mud/setupState";
import { useWallets } from "@privy-io/react-auth";

export type MUDContextResult = MUDStateResult & Partial<SetupResult>;

const MUDContext = createContext<MUDContextResult | null | undefined>(null);

export const publicClient: PublicClient = createPublicClient({
  chain: chain as Chain,
  batch: {
    multicall: true,
  },
  transport: fallback(
    [
      http((chain as Chain)?.rpcUrls?.default?.http[0], {
        batch: true,
      }),
      http((chain as Chain)?.rpcUrls?.public?.http[0], {
        batch: true,
      }),
    ],
    { rank: true }
  ),
});

type Props = {
  children: ReactNode;
};

export const MUDProvider = ({ children }: Props) => {
  const activeWalletClient = useWalletClient();
  const { wallets } = useWallets();

  const { data: mudState } = useQuery({
    queryKey: ["mudState"],
    queryFn: async () => {
      const response = await setupState(publicClient);
      return response;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { data: mud } = useQuery({
    queryKey: ["mud", wallets.length, activeWalletClient.dataUpdatedAt],
    queryFn: async () => {
      if (!mudState || !activeWalletClient.data) return null;

      return setup(
        mudState,
        publicClient,
        activeWalletClient.data as WalletClient
      );
    },
    enabled: !!mudState,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  return (
    <MUDContext.Provider
      value={
        mudState
          ? {
              ...mudState,
              ...mud,
            }
          : undefined
      }
    >
      <LoadingScreen>{children}</LoadingScreen>
    </MUDContext.Provider>
  );
};

export const useMUD = () => {
  const value = useContext(MUDContext);
  return value;
};
