import React, { FC, useCallback } from "react";
import TextFlex from "./ui/TextFlex";
import Text from "./ui/Text";
import Hearts from "./Hearts";
import Button from "./ui/Button";
import { Link } from "react-router-dom";
import { useMUD } from "@/MUDContext";
import { handleError } from "@/lib/error";
import { toast } from "sonner"; // import the Sonner toast

interface StatusWrapperProps {
  id: number;
  name: string;
  stage: string;
  weight: string;
  sick: boolean;
  hurt: boolean;
  tired: boolean;
  effort?: bigint;
  hunger?: bigint;
  giftEggsAvailable?: bigint;
  feedTimer: string;
  canEvolve: boolean;
  onEvolve: () => void;
  canSleep: boolean;
  onSleep: () => void;
  canClean: boolean;
  onClean: () => void;
}

const StatusWrapper: FC<StatusWrapperProps> = ({
  id,
  name,
  stage,
  weight,
  effort,
  sick,
  hurt,
  tired,
  hunger,
  giftEggsAvailable,
  feedTimer,
  canEvolve,
  onEvolve,
  canSleep,
  onSleep,
  canClean,
  onClean,
}) => {
  const mud = useMUD();

  const handleClaimEgg = useCallback(async () => {
    try {
      await mud?.systemCalls?.claimGiftEgg(BigInt(id));
      toast.success("Gift egg claimed!"); // show toast notification  
    } catch (err) {
      handleError(err);
    }
  }, [mud, id]);

  const handleEvolve = useCallback(() => {
    try {
      onEvolve();
      if (stage === "Battle") {
        toast.success("Received a gift egg!"); // show toast notification if stage is Battle
      }
    } catch (err) {
      handleError(err);
    }
  }, [onEvolve, stage]);

  return (
    <>
      <div className="absolute-container left"> 
        <div className="flex flex-col gap-1 opacity-50">
        <TextFlex className="w-full bg-white p-2 border-4 border-black rounded-2xl flex gap-2">
        <Text className="md:text-3xl sm:text-xl">#{id}</Text>
          </TextFlex>
          <TextFlex className="w-full bg-white p-2 border-4 border-black rounded-2xl flex gap-2">
            <Text className="md:text-2xl sm:text-xl">{name}</Text>
          </TextFlex>
          <TextFlex className="w-full bg-white p-2 border-4 border-black rounded-2xl flex gap-2">
            <Text className="md:text-2xl sm:text-xl">Weight: {weight}</Text>
          </TextFlex>
          <TextFlex className="w-full bg-white p-2 border-4 border-black rounded-2xl flex gap-2">
            <Text className="md:text-2xl sm:text-xl">Effort: {Number(effort)}</Text>
          </TextFlex>

          <div>
            {sick && (
              <TextFlex className="p-2 bg-red-500">
                <img
                  src="/images/ui/ui-mood-bad.svg"
                  alt="Sick"
                  width={24}
                  height={24}
                  className="svg"
                />
                <Text className="lg">Sick</Text>
              </TextFlex>
            )}
            {hurt && (
              <TextFlex className="p-2 bg-red-500">
                <img
                  src="/images/ui/ui-mood-worst.svg"
                  alt="Hurt"
                  width={24}
                  height={24}
                  className="svg"
                />
                <Text className="lg">Hurt</Text>
              </TextFlex>
            )}
            {tired && (
              <TextFlex className="p-2 bg-red-500">
                <img
                  src="/images/ui/ui-bed-0.svg"
                  alt="Tired"
                  width={24}
                  height={24}
                  className="svg"
                />
                <Text className="lg">Tired</Text>
              </TextFlex>
            )}
          </div>
        </div>
      </div>
      <div className="absolute-container right">
        <div className="info-wrapper min-w-36">
          <Link to={`/dappmons/${id}/profile`} className="profile self-stretch">
            <Button className="w-full">
              <Text className="lg">Stats</Text>
            </Button>
          </Link>
          {canEvolve && (
            <Button onClick={handleEvolve} className="dark evolve self-stretch w-full">
              <Text className="lg">Evolve</Text>
            </Button>
          )}
          {canSleep && (
            <Button onClick={onSleep} className="dark sleep self-stretch w-full">
              <Text className="lg">Sleep</Text>
            </Button>
          )}
          {canClean && (
            <Button onClick={onClean} className="dark clean self-stretch w-full">
              <Text className="lg">Clean</Text>
            </Button>
          )}
          {(giftEggsAvailable || 0n) > 0n && (
            <Button
              onClick={handleClaimEgg}
              className="dark claim self-stretch w-full"
            >
              <Text className="lg">Claim Egg</Text>
            </Button>
          )}
        </div>
      </div>
      <div className="timer-wrapper">
        <div className="bottom-wrapper">
          <div className="timer-container">
            <Text className="xxl centered">Food:</Text>
            <Hearts value={Number(hunger)} />
            <Text className="xxl">{feedTimer}</Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusWrapper;
