import { useMUD } from "@/MUDContext";
import { useQuery } from "@tanstack/react-query";

export const useShop = () => {
  const mud = useMUD();

  const fetchShopData = async () => {
    if (!mud?.systemCalls) return null;

    const slots = [1, 2, 3, 4]; // Assuming shop has 4 slots
    const itemNames = ["Food", "Protein", "Medicine", "Bandage"];
    const itemQuantity = [20, 5, 5, 5];
    
    const items = await Promise.all(slots.map(async (slot, index) => {
      const price = await mud.systemCalls?.getShopSlotPrice(slot) || 0n;
      const itemId = await mud.systemCalls?.getShopSlotItemId(slot) || 0;
      const owned = await mud.systemCalls?.getItemsBalance(BigInt(itemId)) || 0n; // Get the amount owned by the user
      return { 
        slot, 
        price: (Number(price) / 1e18).toFixed(6), 
        name: itemNames[index], 
        owned: owned.toString(), 
        quantity: itemQuantity[index] 
      };
    }));

    const packagePrice = await mud.systemCalls.getPackagePrice();

    return {
      items,
      packagePrice,
      purchaseItemFromShop: mud.systemCalls.purchaseItemFromShop,
      purchasePackage: mud.systemCalls.purchasePackage,
    };
  };

  const {
    data: shop,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["shop"],
    queryFn: fetchShopData,
    enabled: !!mud?.systemCalls,
    refetchOnWindowFocus: "always",
  });

  return {
    shop,
    isLoading,
    isFetching,
    refetch,
  };
};
