import Text from "@/components/ui/Text";
import TextFlex from "@/components/ui/TextFlex";
import WhiteContainer from "@/components/ui/WhiteContainer";
import GameScreen from "@/components/GameScreen";
import Button from "@/components/ui/Button";
import ButtonGrid from "@/components/ButtonGrid";
import AlertContainer from "@/components/AlertContainer";
import Loader from "@/components/ui/Loader";
import { useComponentValue } from "@latticexyz/react";
import { SyncStep } from "@latticexyz/store-sync";
import { singletonEntity } from "@latticexyz/store-sync/recs";
import { useMUD } from "../MUDContext";

export function LoadingProgress({ children }: { children: React.ReactNode }) {
  const mud = useMUD();

  const syncProgress = useComponentValue(
    mud!.components.SyncProgress,
    singletonEntity,
    {
      message: "Connecting",
      percentage: 0,
      step: SyncStep.INITIALIZE,
      latestBlockNumber: 0n,
      lastBlockNumberProcessed: 0n,
    }
  );

  if (syncProgress.step === SyncStep.LIVE) {
    return children;
  }

  return (
    <div className="app">
      <div className="app-container">
        <div className="navigation-container">
          <TextFlex className="navigation">
            <div className="logo-container">
              <img
                src="/images/dappmon-mark-black.svg"
                loading="lazy"
                alt="Dappmon Mark"
                className="svg logo"
                style={{ cursor: "pointer" }}
              />
            </div>
          </TextFlex>
        </div>
        <WhiteContainer>
          <TextFlex>
            <Text className="lg centered">Loading...</Text>
          </TextFlex>
        </WhiteContainer>
        <GameScreen title="Loading">
          <Loader />
        </GameScreen>
        <AlertContainer message={`${Math.floor(syncProgress.percentage)}%`} />
        <WhiteContainer>
          <ButtonGrid>
            <Button>Loading...</Button>
          </ButtonGrid>
        </WhiteContainer>
      </div>
    </div>
  );
}
