import { Chain } from "viem/chains";

import { MUDChain, mudFoundry } from "@latticexyz/common/chains";

const baseMainnet: Chain = {
  id: 8453,
  name: "Base",
  nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    public: {
      http: ["https://base-rpc.publicnode.com"],
    },
    default: {
      http: [
        "https://api.developer.coinbase.com/rpc/v1/base/0_nPoWX8z25pAXpWg7zuR7XqYOvo7QPU",
      ],
    },
  },
  blockExplorers: {
    default: {
      name: "Basescan",
      url: "https://basescan.org",
      apiUrl: "https://api.basescan.org/api",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 1059647,
    },
  },
  sourceId: 1,
};

const baseSepolia: Chain = {
  id: 84532,
  name: "Base Sepolia",
  nativeCurrency: { name: "Sepolia Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    alchemy: {
      http: [
        "https://base-sepolia.g.alchemy.com/v2/Ok3F48lw5TatOqzBeU8IDeNLxo7KCiKV",
      ],
      webSocket: [
        "wss://base-sepolia.g.alchemy.com/v2/Ok3F48lw5TatOqzBeU8IDeNLxo7KCiKV",
      ],
    },
    public: {
      http: ["https://sepolia.base.org"],
    },
    default: {
      http: [
        "https://base-sepolia.g.alchemy.com/v2/Ok3F48lw5TatOqzBeU8IDeNLxo7KCiKV",
      ],
    },
  },
  blockExplorers: {
    default: {
      name: "Basescan",
      url: "https://sepolia.basescan.org",
      apiUrl: "https://api-sepolia.basescan.org/api",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 1059647,
    },
  },
  testnet: true,
  sourceId: 11_155_111,
};

export const supportedChains: MUDChain[] = [
  mudFoundry,
  baseMainnet,
  baseSepolia,
];

export const chainId = Number(import.meta.env.VITE_CHAIN_ID || 31337);
export const chain =
  supportedChains.find((c) => c.id === chainId) ?? mudFoundry;
