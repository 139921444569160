import React, { FC, useState } from "react";
import { toast } from "sonner";
import Text from "./ui/Text";
import TextFlex from "./ui/TextFlex";
import Button from "./ui/Button";
import ButtonGrid from "./ButtonGrid";
import { useMUD } from "@/MUDContext";

interface BurnDappmonProps {
  dappmonId: string;
  onCancel: () => void;
}

const BurnDappmon: FC<BurnDappmonProps> = ({ dappmonId, onCancel }) => {
  const mud = useMUD();
  const [confirmBurn, setConfirmBurn] = useState(false);

  const handleBurn = async () => {
    try {
      await mud?.systemCalls?.burn(BigInt(dappmonId));
      toast.success("Dappmon burned successfully!");
      onCancel();
    } catch (error) {
      toast.error("Error burning Dappmon. Please try again.");
    }
  };

  return (
    <div>
      {!confirmBurn ? (
        <>
          <TextFlex>
            <TextFlex className="w-full ">
              <Text className="xxl">WARNING:</Text>
              <Text>Are you sure? This is irreversible.</Text>
            </TextFlex>
            <ButtonGrid>
              <Button className="dark" onClick={() => setConfirmBurn(true)}>
                Burn
              </Button>
              <Button onClick={onCancel}>Cancel</Button>
            </ButtonGrid>
          </TextFlex>
        </>
      ) : (
        <>
          <TextFlex>
            <TextFlex className="w-full ">
              <Text className="xxl">WARNING:</Text>
              <Text>Send the Dappmon to 0xDead wallet?</Text>
            </TextFlex>
            <ButtonGrid className="w-full ">
              <Button className="dark" onClick={handleBurn}>
                Yes
              </Button>
              <Button onClick={() => setConfirmBurn(false)}>No</Button>
            </ButtonGrid>
          </TextFlex>
        </>
      )}
    </div>
  );
};

export default BurnDappmon;
