export enum StageType {
  None = 0,
  Egg = 1,
  Baby = 2,
  Child = 3,
  Training = 4,
  Battle = 5,
  Champion = 6,
}

export enum ItemType {
  None = 0,
  Food = 1,
  Bandage = 2,
  Protein = 3,
  Medicine = 4,
}

export enum DappmonType {
  Null = 0,
  Decrypt = 1,
  Encrypt = 2,
  Cipher = 3,
}

export enum SkillType {
  Null = 0,
  Decrypt = 1,
  Encrypt = 2,
  Cipher = 3,
}

export enum SkillCategory {
  Attack = 0,
  Tech = 1,
  Heal = 2,
  Buff = 3,
  Debuff = 4,
  Block = 5,
}

export enum SkillAttributes {
  None = 0,
  Attack = 1,
  Tech = 2,
  Defense = 3,
  TechDefense = 4,
  Speed = 5,
}

export enum OperatorPermissions {
  None = 0,
  Operator = 1,
  SuperOperator = 2,
}

export interface InventoryProps {
  foodCount: bigint;
  proteinCount: bigint;
  bandageCount: bigint;
  medicineCount: bigint;
}

export interface DappmonProps {
  id?: number;
  attack?: number;
  defense?: number;
  tech?: number;
  techDefense?: number;
  speed?: number;
  type?: string;
  stage?: string;
  weight?: string;
  version?: string;
  age?: string;
  hatchDate?: string;
  walletAddress?: string;
  evoTimer?: string;
  elo?: number;
  wins?: number;
  isWalking?: boolean;
  isSleeping?: boolean;
  isSick?: boolean;
  isTired?: boolean;
  isDead?: boolean;
  state: 'idle' | 'happy' | 'sad' | 'attack' | 'dead';
  poop?: number;
  selectedItem?: "food" | "protein" | "bandage" | "medicine" | null;
}
