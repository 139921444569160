import { createBrowserRouter } from "react-router-dom";
import { Root } from "./Root";
import { ErrorPage } from "./ErrorPage";
import { Home } from "./Home";
import { Start } from "./Start";
import { Hatch } from "./Hatch";
import { Shop } from "./Shop";
import { Leaderboard } from "./Leaderboard";
import { Dappmons } from "./Dappmons";
import { Care } from "./Care";
import { Profile } from "./Profile";
import { Settings } from "./Settings";
import { Terms } from "./Terms";
import { Policy } from "./Policy";
import Open from "./Open";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        index: true,
        element: <Home />,
      },
   
      {
        path: "/start",
        element: <Start />,
      },
      {
        path: "/open",
        element: <Open />,
      },
      {
        path: "/shop",
        element: <Shop />,
      },
      {
        path: "/leaderboard",
        element: <Leaderboard />,
      },
      {
        path: "/dappmons",
        element: <Dappmons />,
      },
      {
        path: "/dappmons/:dappmonId",
        element: <Care />,
      },
      {
        path: "/dappmons/:dappmonId/hatch",
        element: <Hatch />,
      },
      {
        path: "/dappmons/:dappmonId/profile",
        element: <Profile />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/policy",
        element: <Policy />,
      },
    ],
  },
]);
