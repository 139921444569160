import React, { FC } from "react";
import { DappmonProps } from "@/types";
import PoopAnimation from "./PoopAnimation";

interface CareRightProps extends Pick<DappmonProps, "poop"> {}

const CareRight: FC<CareRightProps> = ({ poop }) => {
  const maxVisiblePoops = 4; // Or any other number you prefer
  const visiblePoops = Math.min(poop || 0, maxVisiblePoops);

  return (
    <div className="h-full w-full flex items-end justify-center">
      <div className="grid grid-cols-2 gap-2">
        {Array.from({ length: visiblePoops }, (_, index) => (
          <div key={index} className="w-12 h-12 flex items-center justify-center">
            <PoopAnimation pooped={true} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CareRight;