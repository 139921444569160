import React, { FC, useEffect, useState, useCallback } from "react";

interface DappmonAnimationProps {
  id?: number;
  state: "idle" | "happy" | "sad" | "attack" | "dead";
  isWalking?: boolean;
  isSleeping?: boolean;
  isPlayer?: boolean;
  isDead?: boolean;
}

const DappmonAnimation: FC<DappmonAnimationProps> = ({
  id = null,
  state,
  isWalking = false,
  isSleeping = false,
  isPlayer = false,
  isDead = false,
}) => {
  const [images, setImages] = useState({ img0: "", img1: "" });
  const [currentImage, setCurrentImage] = useState("img0");
  const [loading, setLoading] = useState(true);

  const getImages = useCallback(() => {
    const basePath = isDead
      ? "/images/ui/ui-dead"
      : isSleeping
      ? "/images/ui/ui-sleeping"
      : `/images/dappmon/id/${id}/${id}-${state}`;

    return {
      img0: `${basePath}-0.svg`,
      img1: `${basePath}-1.svg`,
    };
  }, [id, state, isSleeping, isDead]);

  useEffect(() => {
    setImages(getImages());
  }, [getImages]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev === "img0" ? "img1" : "img0"));
    }, 500); 
    return () => clearInterval(interval); 
  }, []);

  // Handle image load
  const handleImageLoad = () => {
    setLoading(false); 
  };

  return (
    <div className="dappmon-animation-wrapper">
      <div className="dappmon-animation-center">
        <div className={`dappmon-inner ${isWalking && !isDead && !isSleeping ? 'dappmon-is-walking' : ''}`}>
          <img
            src={currentImage === "img0" ? images.img0 : images.img1}
            alt={state}
            onLoad={handleImageLoad} 
            style={{
              visibility: loading ? "hidden" : "visible", 
              transform: isPlayer ? "scaleX(-1)" : "none",
              width: '100px',
              height: '100px',
            }}
          />
          {loading && <div className="loader" />} {/* Optionally show a loader */}
        </div>
      </div>
    </div>
  );
};

export default DappmonAnimation;
