import React, { FC, useCallback, useState } from "react";
import Navigation from "@/components/Navigation";
import Text from "@/components/ui/Text";
import WhiteContainer from "@/components/ui/WhiteContainer";
import ButtonGrid from "@/components/ButtonGrid";
import Button from "@/components/ui/Button";
import GameScreen from "@/components/GameScreen";
import OpenPackage from "@/components/OpenPackage";
import { useMUD } from "@/MUDContext";
import { usePackages } from "@/hooks/usePackages";
import { toast } from "sonner";
import { handleError } from "@/lib/error";
import { useNavigate } from "react-router-dom";
import AlertContainer from "@/components/AlertContainer";
import { useAuthenticated } from "@/hooks/useAuthenticated";

const Open: FC = () => {
  useAuthenticated();
  const mud = useMUD();
  const { packages } = usePackages();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>(
    "Open a starter package."
  );

  const handleOpenPackage = useCallback(async () => {
    let toastId: string | number | undefined;
    try {
      if (!mud?.systemCalls || !packages || packages.length === 0) return;

      setLoading(true);
      await mud?.systemCalls.openPackage(packages[0]);
      const dappmonIds = await mud?.systemCalls.getDappmonIds();
      toastId = toast.success(
        "Received:\nstarter egg\n20x food\n5x protein\n5x medicine\n5x bandages"
      );
      navigate(`/dappmons/${dappmonIds[dappmonIds.length - 1]}/hatch`);
    } catch (err) {
      handleError(err, toastId);
    } finally {
      setLoading(false);
    }
  }, [mud, navigate, packages]);

  return (
    <>
    <div className="app">
      <div className="app-container">
        <Navigation />
        <WhiteContainer>
          <Text className="lg centered">Open Package</Text>
        </WhiteContainer>
        <GameScreen title="Open Package">
          <OpenPackage />
        </GameScreen>
        <AlertContainer message={alertMessage} />
        <WhiteContainer>
          <ButtonGrid>
            <Button
              className="w-button"
              onClick={handleOpenPackage}
              disabled={loading}
              onMouseOver={() => setAlertMessage("Open?")}
              onMouseOut={() => setAlertMessage("Open a starter package.")}
            >
              {loading ? "Opening..." : "Open Package"}
            </Button>
          </ButtonGrid>
        </WhiteContainer>
      </div>
      </div>
    </>
  );
};

export default Open;
