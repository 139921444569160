import React, { FC } from "react";
import Text from "./ui/Text";
import Button from "./ui/Button";
import Hearts from "./Hearts";
import TextFlex from "./ui/TextFlex";

interface DappmonProfileProps {
  dappmonId: bigint;
  dappmon?: number;
  name: string;
  hp: number;
  attack: number;
  defense: number;
  tech: number;
  techDefense: number;
  speed: number;
  type: string;
  stage: string;
  weight: string;
  basePower: number;
  version: string;
  strength?: number;
  hunger?: number;
  canClean: boolean;
  canSleep: boolean;
  sick: boolean;
  hurt: boolean;
  tired: boolean;
  dead: boolean;
  canEvolve: boolean;
  onClick?: () => void;
  onMouseOver?: (message: string) => void;
  onMouseOut?: () => void;
}

const DappmonProfile: FC<DappmonProfileProps> = ({
  dappmonId,
  dappmon,
  name,
  stage,
  hunger,
  canClean,
  sick,
  hurt,
  tired,
  dead,
  canEvolve,
  onClick,
  onMouseOver,
  onMouseOut,
}) => {
  const handleMouseOver = () => {
    if (onMouseOver) {
      onMouseOver(`Select #${dappmonId.toString()} ${name}?`);
    }
  };

  const imageUrl = dead
    ? "/images/ui/ui-dead-0.svg"
    : dappmon
    ? `/images/dappmon/id/${dappmon}/${dappmon}-idle-0.svg`
    : "/images/ui/ui-1-idle-0.svg";

  return (
    <div
      className="white-container container-select shadow cursor-pointer mx-auto h-full"
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={onMouseOut}
    >
      <TextFlex>
      <div className="flex mx-auto mb-4">
        <img
          src={imageUrl}
          alt="Dappmon"
          width={48}
          height={48}
          className="svg"
        />
        <div className="ml-4">
          <Text className="xl">ID: {Number(dappmonId)}</Text>
          <Text className="xl">{name}</Text>
        </div>
      </div>
      </TextFlex>
      <TextFlex>
      <div className="flex mx-auto items-center mb-4">
        <div className="flex space-x-2">
          {canClean && !dead && (
            <img
              src="/images/ui/ui-poop-0.svg"
              alt="Poop"
              width={24}
              height={24}
              className="svg animate-pulse"
            />
          )}
          {canEvolve && !dead && (
            <img
              src="/images/ui/ui-gem-1.svg"
              alt="Evolve"
              width={24}
              height={24}
              className="svg animate-pulse"
            />
          )}
          {sick && !dead && (
            <img
              src="/images/ui/ui-mood-bad.svg"
              alt="Sick"
              width={24}
              height={24}
              className="svg animate-pulse"
            />
          )}
          {hurt && !dead && (
            <img
              src="/images/ui/ui-mood-worst.svg"
              alt="Hurt"
              width={24}
              height={24}
              className="svg animate-pulse"
            />
          )}
          {tired && !dead && (
            <img
              src="/images/ui/ui-bed-0.svg"
              alt="Tired"
              width={24}
              className="svg animate-pulse"
            />
          )}
          {dead && (
            <img
              src="/images/ui/ui-dead-0.svg"
              alt="Dead"
              width={24}
              height={24}
              className="svg animate-pulse"
            />
          )}
        </div>
        <div className="ml-4">
          <Hearts value={hunger ?? 0} />
        </div>
      </div>
      </TextFlex>
      {stage !== "Egg" && stage !== undefined && (
        <div className="bottom-wrapper w-full mx">
          <div className="stat-wrapper dappmon-stats">
            <Button className="w-full">Select</Button>
          </div>
        </div>
        
      )}
    </div>
   
  );
};

export default DappmonProfile;
