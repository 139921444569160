import React from "react";
import Text from "./ui/Text";
import WhiteContainer from "./ui/WhiteContainer";
import Button from "./ui/Button";
import TextFlex from "./ui/TextFlex";

interface ShopItemProps {
  imageSrc: string;
  name: string;
  owned: number;
  stats: { label: string; value: string }[];
  cost: string;
  loading: boolean;
  onBuy: () => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
}

const ShopItem: React.FC<ShopItemProps> = ({
  imageSrc,
  name,
  owned,
  stats,
  cost,
  loading,
  onBuy,
  onMouseOver,
  onMouseOut,
}) => {
  return (
    <>
      <WhiteContainer className="shadow-inner">
        <TextFlex className="w-full">
          <TextFlex className="stat">
            <img src={imageSrc} alt={name} width={24} height={24} />
            <Text className="xl">{name}</Text>
          </TextFlex>
          <TextFlex className="stat">
            <Text className="lg">Owned</Text>
            <Text className="xl bold">{owned}</Text>
          </TextFlex>
        </TextFlex>
        <WhiteContainer className="shadow-inner mt-1 mb-1">
          {stats.map((stat, index) => (
            <TextFlex key={index} className="stat">
              <Text className="lg">{stat.label}</Text>
              <Text className="xl bold">{stat.value}</Text>
            </TextFlex>
          ))}
          <TextFlex className="stat">
            <Text className="lg">Cost</Text>
            <Text className="xl bold">{cost}</Text>
          </TextFlex>
        </WhiteContainer>
        <Button
          className="w-full"
          onClick={onBuy}
          disabled={loading}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
          <Text className="lg">{loading ? "Purchasing..." : "Buy"}</Text>
        </Button>
      </WhiteContainer>
    </>
  );
};

export default ShopItem;